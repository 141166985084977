import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import ProgressChart from '../ProgressChart';

ChartJS.register(ArcElement, Tooltip, Legend);

interface ProgressCardProps {
    id: number;
    subject: string;
    total: number;
    complete: number;
    inProgress: number;
    needsApproval: number;
    revisit: number;
    issues: number;
}

export const ProgressCard: React.FC<ProgressCardProps> = ({
    id,
    subject,
    total,
    complete,
    inProgress,
    needsApproval,
    issues,
    revisit }) => {

    const completePercent = Math.round((complete / total) * 100);
    const inProgressPercent = Math.round((inProgress / total) * 100);
    const remaining = total - complete - inProgress;

    const borderColor = issues > 0 ? '#FF5757' : needsApproval > 0 ? '#FFBD59' : inProgress > 0 ? '#5CE1E6' : '#7ED957';

    return (
        <Link to={`/dashboard/${id}`}>
            <Card
                sx={{
                    minWidth: 200,
                    width: '95%',
                    p: 0,
                    backgroundColor: '#111',
                    color: 'white',
                    borderRadius: '40px',
                    transition: '0.3s',
                    '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.5)',
                    },
                    cursor: 'pointer',
                    border: `6px solid ${borderColor}`
                }}>
                <CardContent>
                    <Typography sx={{ fontSize: 40, fontWeight: 'bold', textAlign: 'center' }}>
                        {subject}
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Box sx={{ position: 'relative', mb: 2, height: '160px', width: '100%' }}>
                                <ProgressChart complete={complete} inProgress={inProgress} remaining={remaining} revisit={revisit} tooltipEnabled={true} />
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography
                                        variant='h3'
                                        component="div"
                                        color="text.primary"
                                        sx={{ position: 'absolute', textAlign: 'center', fontWeight: 'bold', color: '#FFBD59' }}>
                                        {needsApproval}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Box>
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#7ED957', lineHeight: '1.05' }}>{`${completePercent}% Complete`}</Typography>
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#5CE1E6', lineHeight: '1.05' }}>{`${inProgressPercent}% In Progress`}</Typography>
                                {revisit !== 0 && <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FF66C4', lineHeight: '1.05' }}>{`${revisit} Revisit`}</Typography>}
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FFBD59', lineHeight: '1.05' }}>{`${needsApproval} Needs Approval`}</Typography>
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FF5757', lineHeight: '1.05' }}>{`${issues} Issues`}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Link>
    );
};