import React from 'react';
import { Message, NotificationContainer, CloseButton } from './style';

interface NotificationProps {
  data: { status: string; messages: string[] };
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ data, onClose }) => {
  const message = Array.isArray(data.messages) ? data.messages.join(' ') : data.messages;

  const formatMessage = (msg: string) => {
    if (msg.includes(',')) {
      return (
        <ul>
          {msg.split(',').map((part, index) => (
            <li key={index}>{part.trim()}</li>
          ))}
        </ul>
      );
    }
    return <span>{msg}</span>;
  };

  return (
    <NotificationContainer status={data.status}>
      <Message>
        {formatMessage(message)}
      </Message>
      <CloseButton onClick={onClose}>&times;</CloseButton>
    </NotificationContainer>
  );
};

export default Notification;
