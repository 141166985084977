import React, { createContext, useContext, useState } from 'react';

interface TitleContextProviderProps {
    children: React.ReactNode;
}

type TitleContextType = {
    title: string;
    setTitle: (title: string) => void;
};

const TitleContext = createContext<TitleContextType | undefined>(undefined);

export const useTitle = () => {
    const context = useContext(TitleContext);
    if (!context) {
        throw new Error('useTitle must be used within a TitleProvider');
    }
    return context;
};

const DEFAULT_SUFFIX = ' - Saintsburg Portal';

export const TitleProvider: React.FC<TitleContextProviderProps> = ({ children }) => {
    const [title, setTitle] = useState<string>('Home' + DEFAULT_SUFFIX);
    const setModifiedTitle = (newTitle: string) => {
        document.title = newTitle + DEFAULT_SUFFIX;
        setTitle(newTitle + DEFAULT_SUFFIX);
    };

    return (
        <TitleContext.Provider value={{ title, setTitle: setModifiedTitle }}>
            {children}
        </TitleContext.Provider>
    );
};
