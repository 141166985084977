import React, { useEffect } from "react";
import { GoConfig, useActiveAuthProvider, useGetIdentity, useGo, useIsAuthenticated, useParsed, useRouterContext, useRouterType, useNavigation } from "@refinedev/core";

const useDeferredGo = () => {
    const go = useGo();

    const [config, setConfig] = React.useState<GoConfig | undefined>(undefined);

    React.useEffect(() => {
        if (config) {
            go(config);
        }
    }, [config]);

    const cb = React.useCallback(
        (props: GoConfig) => {
            if (!config) {
                setConfig(props);
            }
        },
        [config],
    );

    return cb;
};

export type RoleAuthenticatedProps = {
    redirectOnFail?: string | true;
    appendCurrentPathToQuery?: boolean;
    fallback?: React.ReactNode;
    loading?: React.ReactNode;
    children?: React.ReactNode;
    role?: string;
    roles?: string[];
};

export const RoleAuthenticated = ({
    redirectOnFail = true,
    appendCurrentPathToQuery = true,
    children,
    fallback: fallbackContent,
    loading: loadingContent,
    role,
    roles, }: RoleAuthenticatedProps): JSX.Element | null => {
    const activeAuthProvider = useActiveAuthProvider();
    const routerType = useRouterType();

    const hasAuthProvider = Boolean(activeAuthProvider?.isProvided);
    const isLegacyAuth = Boolean(activeAuthProvider?.isLegacy);
    const isLegacyRouter = routerType === "legacy";

    const parsed = useParsed();
    const go = useGo();
    const deferredGo = useDeferredGo();
    const { replace } = useNavigation();
    const { useLocation } = useRouterContext();
    const legacyLocation = useLocation();

    const {
        isLoading,
        isFetching,
        // isRefetching,
        isSuccess,
        data: {
            authenticated: isAuthenticatedStatus,
            redirectTo: authenticatedRedirect,
        } = {},
        refetch,
    } = useIsAuthenticated();

    const { data: identity } = useGetIdentity();

    const identity_roles = identity as string[];

    useEffect(() => {
        refetch();
    }, [children, fallbackContent]);

    const state = React.useRef<{
        status: "initial" | "pending" | "settled";
        content: React.ReactNode;
    }>({
        status: isLoading ? "initial" : "pending",
        content: loadingContent ?? null,
    });

    if (isFetching) {
        state.current.status = "pending";
    } else if (!isFetching) {
        state.current.status = "settled";
    }

    const isAuthenticated = hasAuthProvider
        ? isLegacyAuth
            ? isSuccess
            : isAuthenticatedStatus
        : true;

    const checkRole = () => {
        if (role) {
            return identity_roles.includes(role as string)
        }
        if (roles) {
            return roles.some(r => identity_roles.includes(r))
        }
        return false;
    }

    if (state.current.status === "settled") {
        if (isAuthenticated && checkRole()) {
            state.current.content = <>{children ?? null}</>;
        }
        else if (typeof fallbackContent !== "undefined") {
            state.current.content = <>{fallbackContent}</>;
        }
        else {
            const pathname = `${isLegacyRouter ? legacyLocation?.pathname : parsed.pathname
                }`.replace(/(\?.*|#.*)$/, "");

            const appliedRedirect = isLegacyAuth
                ? typeof redirectOnFail === "string"
                    ? redirectOnFail
                    : "/login"
                : typeof redirectOnFail === "string"
                    ? redirectOnFail
                    : (authenticatedRedirect as string | undefined);

            if (appliedRedirect) {
                if (isLegacyRouter) {
                    const toQuery = appendCurrentPathToQuery
                        ? `?to=${encodeURIComponent(pathname)}`
                        : "";
                    replace(`${appliedRedirect}${toQuery}`);
                } else {
                    deferredGo({
                        to: appliedRedirect,
                        query: appendCurrentPathToQuery
                            ? {
                                to: parsed.params?.to
                                    ? parsed.params.to
                                    : go({
                                        to: pathname,
                                        options: { keepQuery: true },
                                        type: "path",
                                    }),
                            }
                            : undefined,
                        type: "replace",
                    });
                }
            }
        }
    }

    if (!hasAuthProvider) {
        return <>{children ?? null}</>;
    }

    return <>{state.current.content}</>;
}