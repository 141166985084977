import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useApiUrl, useList } from "@refinedev/core";
import { axiosInstance } from "@refinedev/simple-rest";
import { Col, Row, Typography } from "antd";

import { Developer, DeveloperContentItemDetail, IActionObject, OperationProgress, SubjectDeveloper, SubjectDeveloperOverview, ToDoData } from "interfaces";
import { ToDoCard } from "components/dashboards/developer-dashboard/ToDoCard";
import { DeveloperTable } from "components/dashboards/developer-dashboard/DeveloperTable";
import { ActionFormModal } from "components/dashboards/developer-dashboard/ActionFormModal";
import { useTitle } from "contexts/TitleContext";

const { Title } = Typography;

export const DevDashboard: React.FC = () => {
    const location = useLocation();
    const apiUrl = useApiUrl();
    const [version, setVersion] = useState(0);
    const { data, isLoading } = useList({
        resource: `dashboard/developer?version=${version}&`
    });

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle('Tasks');
    }, []);
    
    const rawData = data?.data as SubjectDeveloperOverview | undefined;
    const tableData = rawData?.details as DeveloperContentItemDetail[] | undefined ?? [];
    const subject_users = rawData?.users as SubjectDeveloper | undefined ?? {};
    
    const overdueCount = tableData.reduce((count, currentItem) => {
        if (currentItem.status === "Overdue") {
            return count + 1;
        }
        return count;
    }, 0);
    const [open, setOpen] = useState(false);
    const [modalStage, setModalStage] = useState<string>("initial");
    const [selectedItem, setSelectedItem] = useState<DeveloperContentItemDetail>({} as DeveloperContentItemDetail);
    const [users, setUsers] = useState<Developer[]>([]);
    const [operationId, setOperationId] = useState<number>(0);
    const [operationProgress, setOperationProgress] = useState<OperationProgress>({} as OperationProgress);
    const [issueReason, setIssueReason] = useState<string>("");
    const [time, setTime] = useState<string>("");

    const handleActionButtonClick = (subject_name: string, item: DeveloperContentItemDetail) => {
        if (subject_users && subject_name in subject_users) {
            setUsers(subject_users[subject_name]);
        }
        setSelectedItem(item);
        setModalStage("initial");
        setOpen(true);
        setIssueReason('');
        setTime('');
    }

    const handleModal = async (isCancel: boolean, action?: IActionObject) => {
        if (!isCancel && action) {
            handleBeginOperation(action);
        }
        else {
            setModalStage("initial");
            setOpen(false);
            setIssueReason('');
            setTime('');
        }
    }

    const handleBeginOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/content`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getProgress = async () => {
        const response = await axiosInstance.get(`${apiUrl}/dashboard/content/${operationId}/progress`, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        setOperationProgress(response.data);
        return response;
    }

    useEffect(() => {
        if (operationId === 0) return;

        var stayOpen = true;
        const intervalId = setInterval(async () => {
            if (!stayOpen) {
                handleModalClose();
                setOperationId(0);
                setVersion(v => v + 1);
                clearInterval(intervalId);
            }
            else {
                try {
                    const response = await getProgress();
                    stayOpen = response.data.percent < 100;
                } catch (error) {
                    console.error(error);
                    setOperationId(0);
                    handleModalClose();
                    clearInterval(intervalId);
                }
            }

        }, 1500);
    }, [operationId])

    const handleModalClose = async () => {
        setModalStage("initial");
        setOperationId(0);
        setOperationProgress({} as OperationProgress);
        setOpen(false);
        setIssueReason('');
        setTime('');
    }

    return (
        <>
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={20}>
                    <Col span={24}>
                        <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>Tasks</Title>
                    </Col>
                </Col>
                <Col span={4}>
                    <ToDoCard tasks={tableData.length} overdue={overdueCount} />
                </Col>
            </Row>
            <Row>
                <DeveloperTable
                    tableData={tableData}
                    handleActionButtonClick={handleActionButtonClick}
                />
                {open &&
                    <ActionFormModal
                        stage={modalStage}
                        item={selectedItem}
                        users={users}
                        open={open}
                        time={time}
                        operationProgress={operationProgress}
                        issueReason={issueReason}
                        updateStage={setModalStage}
                        handleModal={handleModal}
                        setIssueReason={setIssueReason}
                        setTime={setTime}
                    />
                }
            </Row>
        </>
    )
};