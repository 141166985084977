import React, { useEffect, useState } from "react";
import { useApiUrl, useList } from "@refinedev/core";
import { useLocation, useParams } from "react-router-dom";
import { Col, Row, Typography } from "antd";
import { axiosInstance } from "@refinedev/simple-rest";
import { ContentItemDetail, Developer, IActionObject, LoggedIssue, OperationProgress, ToDoData } from "interfaces";
import { DesktopOutlined } from "@ant-design/icons";
import { BreadCrumbModule } from "components/dashboards/team-leader-dashboard/BreadCrumbModule";
import { ToDoCard } from "components/dashboards/team-leader-dashboard/ToDoCard";
import { ContentTable } from "components/dashboards/team-leader-dashboard/ContentTable";
import { ActionFormModal } from "components/dashboards/team-leader-dashboard/ActionFormModal";
import { useTitle } from "contexts/TitleContext";
import { ReasonModal } from "components/dashboards/team-leader-dashboard/ReasonModal";

const { Title } = Typography;

export const ContentOverview: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const apiUrl = useApiUrl();

    const [version, setVersion] = useState(0);

    const { data } = useList<ContentItemDetail>({
        resource: `dashboard/team_leader/subject/${id}/content?content_type=${location.state.content_type}&version=${version}&`
    });

    const { data: userData } = useList<Developer>({
        resource: `dashboard/team_leader/department/list/${location.state.subject_id}`
    });

    const { data: toDoData } = useList<ToDoData>({
        resource: "dashboard/team_leader/todo",
    });

    const tableData = data?.data ?? [];
    const users = userData?.data ?? [];
    const toDo = toDoData?.data as ToDoData | undefined;

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle(`${location.state.content_type_name ?? "Content"} (${location.state.subject ?? "Overview"})`);
    }, []);

    const [open, setOpen] = useState(false);
    const [modalStage, setModalStage] = useState<string>("initial");
    const [selectedItem, setSelectedItem] = useState<ContentItemDetail>({} as ContentItemDetail);
    const [operationId, setOperationId] = useState<number>(0);
    const [operationProgress, setOperationProgress] = useState<OperationProgress>({} as OperationProgress);
    const [issueReason, setIssueReason] = useState<string>("");
    const [loggedIssue, setLoggedIssue] = useState<LoggedIssue[]>([]); 
    const [reasonModalVisible, setReasonModalVisible] = useState(false);

    const handleActionButtonClick = (item: ContentItemDetail) => {
        setSelectedItem(item);
        setModalStage("initial");
        setOpen(true);
        setIssueReason('');
    }

    const handleRowIdClick = async (row_id: number) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/team_leader/issue-report`, { row_id }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const data = response.data;
                setLoggedIssue(data);
                setReasonModalVisible(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleModal = async (isCancel: boolean, action?: IActionObject) => {
        if (!isCancel && action) {
            handleBeginOperation(action);
        }
        else {
            setModalStage("initial");
            setOpen(false);
            setIssueReason('');
        }
    }

    const handleBeginOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/content`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getProgress = async () => {
        const response = await axiosInstance.get(`${apiUrl}/dashboard/content/${operationId}/progress`, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        setOperationProgress(response.data);
        return response;
    }

    useEffect(() => {
        if (operationId === 0) return;

        var stayOpen = true;
        const intervalId = setInterval(async () => {
            if (!stayOpen) {
                if (location.state.content_type.includes("multi_write") && selectedItem.status === "Not Started") {
                    setModalStage("open_sheet");
                }
                else {
                    clearInterval(intervalId);
                    handleModalClose();
                    setOperationId(0);
                }

                setVersion(v => v + 1);
                clearInterval(intervalId);
            }
            else {
                try {
                    const response = await getProgress();
                    stayOpen = response.data.percent < 100;
                } catch (error) {
                    console.error(error);
                    setOperationId(0);
                    handleModalClose();
                    clearInterval(intervalId);
                }
            }

        }, 1500);
    }, [operationId])

    const handleModalClose = async () => {
        setVersion(v => v + 1);
        setModalStage("initial");
        setOperationId(0);
        setOperationProgress({} as OperationProgress);
        setOpen(false);
        setIssueReason('');
    }

    const closeModal = () => {
        setReasonModalVisible(false);
        setIssueReason('');
        setLoggedIssue([]);
    }

    const breadCrumbItems = [
        { href: "/dashboard", title: <><DesktopOutlined /><span>Dashboard</span></> },
        { href: `/dashboard/${id}`, title: "Content List" },
    ];

    return (
        <>
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={20}>
                    <Col span={24}>
                        <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>{location.state.content_type_name} ({location.state.subject})</Title>
                    </Col>
                    <Col span={24} style={{ marginLeft: '10px', marginTop: '20px' }}>
                        <BreadCrumbModule items={breadCrumbItems} />
                    </Col>
                </Col>
                <Col span={4}>
                    <ToDoCard needsApproval={toDo?.needs_approval ?? 0} />
                </Col>
            </Row>
            <Row>
                <ContentTable
                    tableData={tableData}
                    handleActionButtonClick={handleActionButtonClick}
                    handleRowIdClick={handleRowIdClick}
                />
                {reasonModalVisible &&
                    <ReasonModal
                        visible={reasonModalVisible}
                        loggedIssue={loggedIssue} 
                        onClose={closeModal}
                    />
                }
                {open &&
                    <ActionFormModal
                        stage={modalStage}
                        item={selectedItem}
                        users={users}
                        open={open}
                        issueReason={issueReason}
                        operationProgress={operationProgress}
                        updateStage={setModalStage}
                        handleModal={handleModal}
                        setIssueReason={setIssueReason}
                    />
                }
            </Row>
        </>
    )
};