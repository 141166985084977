import { createContext, useContext, useState } from 'react';

interface AssignmentData {
    assignmentName: string;
    instructions: string;
    attachments: any[];
    pointsPossible: number;
    attemptsAllowed: number;
}

interface AssignmentDataProviderProps {
    children: React.ReactNode;
}

const AssignmentDataContext = createContext<any>(null);

export const useAssignmentData = () => useContext(AssignmentDataContext);

export const AssignmentDataProvider: React.FC<AssignmentDataProviderProps> = ({ children }) => {
    const [assignmentData, setAssignmentData] = useState<AssignmentData | null>(null);

    return (
        <AssignmentDataContext.Provider value={{ assignmentData, setAssignmentData }}>
            {children}
        </AssignmentDataContext.Provider>
    );
};
