import { Col, Row, Typography} from "antd";
import { useParams } from "react-router-dom";
import { HttpError, useList, useLogout } from "@refinedev/core";
import { ICourse, ICourseAssignments, IStudent } from "interfaces";
import { useEffect, useState } from "react";
import { CountCard } from "components/hs-marking/students/CountCard";
import { CoursesGrid } from "components/hs-marking/courses/CoursesGrid";
import { useTitle } from "contexts/TitleContext";

const { Title } = Typography;
const TagColor = (value: number) => value >= 65 ? "success" : value >= 40 ? "warning" : "error";

export const CourseList: React.FC = () => {
    const [assignments, setAssignments] = useState(0);
    const {id} = useParams();
    const {mutate: logout} = useLogout();
    const { data, isLoading } = useList<ICourseAssignments, HttpError>({
        resource: `students/${id}`,
        errorNotification: () => {
            logout();
            return {
                message: "Please login again",
                description: "Session expired",
                type: "error"
            }
        }
    });
    const student = data?.data as unknown as IStudent;
    const student_name = student?.name;
    const courses: ICourse[] = student?.courses;

    const nameMapping: { [key: string]: string } = {
        "Afrikaans Second Language": "Afrikaans",
        "Computer Science": "Comp. Science",
        "Computer Literacy": "Computer Lit.",
        "Information Technology": "IT",
        "Physics LAB": "Physics LAB",
        "Physics": "Physics",
        "Business Studies": "Business Studies",
    };

    const extractSimplifiedName = (originalName: string): string => {
      const lowerCaseName = originalName.toLowerCase();
      const parts = originalName.split(' ');
      let hasEpf = lowerCaseName.includes('epf');
      let simplifiedName = originalName;

      if (originalName.includes("Pure")) {
          const pureIndex = parts.indexOf("Pure");
          const relevantParts = parts.slice(pureIndex, pureIndex + 3);
          simplifiedName = relevantParts.join(" ");
      } else {
        for (const key in nameMapping) {
          if (originalName.includes(key)) {
            simplifiedName = nameMapping[key];
            break;
          }
        }
        if (parts.length >= 2 && !originalName.includes("Physics")) {
          simplifiedName = parts[1];
        }
      }
      if (hasEpf) {
        simplifiedName = 'EP ' + simplifiedName;
      }
       return simplifiedName;
    };

      const modifiedCardsData = courses?.map((course) => ({
        ...course,
        name: extractSimplifiedName(course.name),
      }));

      const { setTitle } = useTitle();

      useEffect(() => {
        setTitle(`${student_name ?? "View student"}`);
        if (student && student.courses) {
          const totalAssignments = student.courses.reduce((total, course) => {
            return total + course.assignments;
          }, 0);
          setAssignments(totalAssignments);
        }
      }, [student]);
      
    return (
        <>
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={20}>
                    <Col span={24}>
                        <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>{ student_name }</Title>
                    </Col>
                </Col>
                <Col span={4}>
                    <CountCard assignments= {assignments}/>
                </Col>
            </Row>
            <Row>
                <CoursesGrid cardsData={modifiedCardsData}/>
            </Row>
        </>
    )
}