import React, { useState } from "react";
import { Button, Spin } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useParams } from "react-router-dom";
import { ISubmission } from "interfaces";
import { Pagination } from '../Submissions/pagination';
import '../Submissions/viewer.css';

interface MarkingSchemeProps {
  scroll: any;
  submission: any;
}

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const MarkingScheme: React.FC<MarkingSchemeProps> = ({
  scroll,
  submission,
}) => {
  const { id, course_id } = useParams();
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loadingPdf, setLoadingPdf] = useState<boolean>(true);
  const preview_url = scroll?.attachments.length > 0 ? scroll.attachments[0].url : '';
  const download_url = submission?.attachments.length > 0 ? submission.attachments[0].url : '';
  const attachment_url = submission?.attachments.length > 0 ? submission.attachments[0].url: '';
  // const filename = scroll?.attachments.length > 0 ? scroll.attachments[0].filename : '';
  // const isPDF = filename.toLowerCase().endsWith(".pdf");

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setLoadingPdf(false);
  };

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const hasMarkingScheme = !!(scroll && scroll.attachments?.length > 0);

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          href={preview_url}
          target="_blank"
          rel="noreferrer"
          icon={<EyeOutlined />}
          style={{ marginRight: 12 }}
          disabled={!hasMarkingScheme}
        >
          View
        </Button>
        <a href={download_url} download>
          <Button type="default" icon={<DownloadOutlined />} disabled={!hasMarkingScheme}>
            Download
          </Button>
        </a>
      </div>
      <div style={{ marginTop: 16, textAlign: "center" }}>
        {hasMarkingScheme ? (
          <div style={{ position: "relative", overflow: "hidden" }}>
            {loadingPdf && <Spin />}
            <Document
              file={attachment_url}
              onLoadSuccess={onDocumentLoadSuccess}
              renderMode="canvas"
              options={{ workerSrc: "/pdf.worker.js" }}
              className="pdf-document"
            >
              <Page
                pageNumber={pageNumber}
                width={500}
                renderTextLayer={false}
              />
            </Document>
            <Pagination
              currentPage={pageNumber}
              totalPages={numPages}
              onPageChange={handlePageChange}
            />
          </div>
        ) : (
          <p>No Marking Scheme</p>
        )}
      </div>
    </div>
  );
};
