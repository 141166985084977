import React from 'react';
import { Modal } from 'antd';
import { IAssignment } from 'interfaces';

interface ExcuseModalProps {
  isVisible: boolean;
  selectedAssignment: IAssignment | null;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ExcuseModal: React.FC<ExcuseModalProps> = ({ isVisible, selectedAssignment, onConfirm, onCancel }) => {
  return (
    <Modal
      title="Confirm"
      visible={isVisible}
      onOk={onConfirm}
      onCancel={onCancel}
    >
      <p>Are you sure you want to mark this assignment as excused?</p>
    </Modal>
  );
};
