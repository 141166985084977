import { Button, Modal } from 'antd';
import styled from 'styled-components';

export const FormButton = styled(Button)`
    &&.ant-btn {
        width: 50%;
        border-radius: 15px;
    }
`;

export const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;