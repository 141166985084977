import React from 'react';
import { List, Card, Typography, Progress, Button, Tooltip } from 'antd';
import { EditOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';

import { Question } from 'interfaces';

type QuestionsContentProps = {
    questions: Question[];
    blankQuestion: Question;
    percentComplete: number;
    editMode: boolean;
    newQuestion: () => void;
    selectQuestion: (question: Question) => void;
    editQuestion: (question: Question) => void;
    showConfirm: () => void;
    showUnusedParts: () => void;
}

const { Text } = Typography;

export const QuestionsContent: React.FC<QuestionsContentProps> = ({
    questions,
    blankQuestion,
    percentComplete,
    editMode,
    newQuestion,
    selectQuestion,
    editQuestion,
    showConfirm,
    showUnusedParts,
}) => {
    return (
        <div className="question-container">
            <List
                grid={{ gutter: 16, column: 4 }}
                className="question-list"
                dataSource={[...questions, blankQuestion]}
                renderItem={(item) =>
                    item.title === 'new' ? (
                        <List.Item className="new-question-item">
                            <Card
                                className="new-question-card"
                                onClick={newQuestion}
                            >
                                <div className="plus-symbol">+</div>
                            </Card>
                        </List.Item>
                    ) : (
                        <List.Item className="question-item">
                            <Card
                                className="question-card"
                                style={{
                                    backgroundColor: '#fff',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                }}
                                cover={<div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                    }}>
                                    <img
                                        alt="thumbnail"
                                        src={`${item.parts[0].part.url}?v=${item.parts[0].part.version}`}
                                        style={{
                                            minWidth: '100%',
                                            minHeight: '100%',
                                            width: 'auto',
                                            height: 'auto',
                                            objectFit: 'contain',
                                            backgroundColor: '#fff',
                                        }}
                                    />
                                    {((!item.memo_parts || item.memo_parts.length === 0) && item.type !== 'MC') && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100%',
                                                height: '100%',
                                                fontSize: '2em',
                                                zIndex: 1,
                                            }}
                                        >
                                            <ExclamationCircleTwoTone twoToneColor="#FF0000" style={{ fontSize: '1em' }} />
                                        </div>
                                    )}
                                </div>}
                                onClick={selectQuestion.bind(this, item)}
                            >
                                <div className="overlay">
                                    <Text strong>{item.title}</Text>
                                    <EditOutlined className="edit-icon" />
                                </div>
                            </Card>
                        </List.Item>
                    )
                }
            />
            <div className="question-pane-footer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Progress percent={percentComplete} status="active" showInfo={false} style={{ margin: '0 5px' }} />
                <Tooltip title="Show unused parts">
                    <Button
                        type="default"
                        onClick={showUnusedParts}
                        style={{ margin: '0 5px' }}
                    >
                        {`${percentComplete}%`}
                    </Button>
                </Tooltip>
                <Button type="primary" onClick={showConfirm} style={{ margin: '0 5px' }}>
                    Finish
                </Button>
            </div>
        </div>
    )
} 