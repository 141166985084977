import React from 'react';
import Grid from '@mui/material/Grid';
import { StudentCard } from '../StudentCard/index';
import { IStudent } from 'interfaces';

interface StudentGridProps {
  cardsData: IStudent[] | undefined;
}

export const StudentGrid: React.FC<StudentGridProps> = ({ cardsData }) => {
  return (
    <Grid container spacing={2} alignItems="stretch">
      {cardsData?.map((student, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <StudentCard
            id={student.id} 
            student={student.name}
            courses={student.courses} 
          />
        </Grid>
      ))}
    </Grid>
  );
};
