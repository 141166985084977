import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { StudentCourseOverview } from 'interfaces';

interface AssignmentCardProps {
  overview: StudentCourseOverview | null;
}

export const AssignmentCard: React.FC<AssignmentCardProps> = ({ overview }) => {
  const { id } = useParams();

  if (!overview) {
    return <div>No data available</div>;
  }

  const totalSubmissions = Number(overview.counts.submissions);
  const totalAssignments = Number(overview.counts.needs_grading);
  const totalOutstanding = Number(overview.counts.outstanding);
  const totalAutoGraded = Number(overview.counts.auto_graded);
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 240,
        width: '95%',
        p: 0,
        backgroundColor: '#111',
        color: 'white',
        borderRadius: '40px',
        transition: '0.3s',
        transform: 'scale(1.2)',
      }}
    >
      <CardContent sx={{ padding:'16px !important' }}>
        <Grid container>
          <Grid item>
            <Box>
              <Typography sx={{ fontSize: 21, fontWeight: 'bold', color: '#FFDE59', lineHeight: '1.05' }}>
                {`${totalAssignments} Needs Grading`}
              </Typography>
              <Typography sx={{ fontSize: 21, fontWeight: 'bold', color: '#7ED957', lineHeight: '1.05' }}>
                {`${totalSubmissions} Graded`}
              </Typography>
              <Typography sx={{ fontSize: 21, fontWeight: 'bold', color: '#5CE1E6', lineHeight: '1.05' }}>
                {`${totalAutoGraded} Auto-Graded`}
              </Typography>
              <Typography sx={{ fontSize: 21, fontWeight: 'bold', color: '#FF5757', lineHeight: '1.05' }}>
                {`${totalOutstanding} Missing`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
