import styled from 'styled-components';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';

export const StyledTable = styled((props: TableProps<any>) => <Table {...props} />)`
    && .ant-table-cell-row-hover {
        background: rgba(20, 20, 20, 1) !important;
        cursor: pointer !important;
    }

    table {
        border-collapse: separate !important;
        border-spacing: 0 0.5rem !important;
    }

    .ant-table {
        background: transparent !important;
        font-size: 20px;
        font-weight: bold;
    }

    .ant-table-thead>tr>td {
        background: transparent !important;
    }

    .ant-table-thead > tr > th {
        background: #000 !important; 
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        border-top: 1px solid #d9d9d9;
        border-bottom: none;
        padding: 8px 16px;
        text-align: center;
        vertical-align: middle;
    }

    .ant-table-thead > tr > th:nth-child(1), th:nth-child(2) {
        opacity: 0;
    }

    .ant-table-thead > tr > th:nth-child(3) {
        border-top-left-radius: 35px !important;
        border-bottom-left-radius: 35px !important;
    }
      
    .ant-table-thead > tr > th:last-child {
        border-top-right-radius: 35px !important;
        border-bottom-right-radius: 35px !important;
    }

    .ant-table-tbody>tr>td:first-child {
        border-top-left-radius: 35px !important;
        border-bottom-left-radius: 35px !important;
    }

    .ant-table-tbody>tr>td:last-child {
        border-top-right-radius: 35px !important;
        border-bottom-right-radius: 35px !important;
    }
         
    .black-background {
        background-color: black;
        color: white;
        text-align: center;
    }

    td.ant-table-column-sort {
        background-color: black;
        color: white;
        text-align: center;
    }

    .complete-col {
        color: rgb(126, 217, 87, 1) !important;
        font-weight: bold;
    }

    .in-progress-col {
        color: rgb(92, 225, 230, 1) !important;
        font-weight: bold;
    }

    .needs-approval-col {
        color: rgb(255, 189, 89, 1) !important;
        font-weight: bold;
    }

    .issue-col {
        color: rgb(255, 87, 87, 1) !important;
        font-weight: bold;
    }

    .revisit-col {
        color: rgb(255, 102, 196, 1) !important;
        font-weight: bold;
    }

`;
