import { Button, Modal } from "antd"

interface IssueModalProps {
    open: boolean;
    onOkClick: () => void;
    issueText: JSX.Element;
}

export const IssueModal = ({ open, onOkClick, issueText }: IssueModalProps) => {
    return (
        <Modal
            open={open}
            onOk={onOkClick}
            onCancel={onOkClick}
            title={"Issue Reason"}
            centered
            footer={[
                <Button key="submit" type="primary" onClick={onOkClick} style={{ borderRadius: '15px' }}>Close</Button>
            ]}>
            <div>{issueText}</div>
        </Modal>
    )

}