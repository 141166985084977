import { HttpError, useOne, } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { AssignmentOptionSelector } from "components";
import { ICourse } from "interfaces";
import { useTitle } from "contexts/TitleContext";
import { useEffect } from "react";

export const CourseSingle: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const { data, isLoading } = useOne<ICourse, HttpError>({
        resource: "courses",
        id: id
    });

    const course = data?.data as unknown as ICourse;

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle(`${course?.name ?? "Course"}`);
    }, []);

    return (
        <div>
            {!isLoading ? (
                <AssignmentOptionSelector courseTitle={course?.name} />
            ) : (
                <></>
            )}
        </div>
    )
}