import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

interface ToDoCardProps {
    needsApproval: number;
}

export const ToDoCard: React.FC<ToDoCardProps> = ({ needsApproval }) => {
    return (
        <Card
            sx={{
                width: '100%',
                p: 0,
                backgroundColor: '#111',
                color: 'white',
                borderRadius: '40px',
            }}>
            <CardContent>
                <Typography sx={{ fontSize: 40, fontWeight: 'bold', color: '#CB6CE6', textAlign: 'left', pl: 2 }}>To Do:</Typography>
                <Grid container>
                    <Box sx={{ pl: 2 }}>
                        <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FFBD59', textAlign: 'left' }}>{`${needsApproval} Approvals`}</Typography>
                    </Box>
                </Grid>
            </CardContent>
        </Card>
    );
};