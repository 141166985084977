import styled from 'styled-components';

export const NotificationContainer = styled.div<{ status: string }>`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: ${props => (props.status === 'fail' ? '#f44336' : '#4caf50')};
  color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 250px;
  max-width: 400px;
`;

export const Message = styled.div`
  font-size: 16px;
  text-align: left;
  width: 90%;

  ul {
    padding-left: 20px;
    margin: 0;
    list-style-type: disc;
  }

  li {
    margin-bottom: 5px;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 25px;
  cursor: pointer;
`;
