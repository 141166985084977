import { MemoPdfPart, PdfPage, PdfPart, SelectedPart } from 'interfaces';
import { useCallback, useEffect, useState } from 'react';
import { pdfjs, Page as ReactPage } from 'react-pdf';
import { Part } from '../part/part';
import { Button, Form, Input, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';

interface PageProps {
    pageNumber: number;
    pageIndex: number;
    ref: any;
    paper: string;
    pdfPage: PdfPage;
    paperType: string;
    amazonImgUrl: string;
    pdfId: number;
    syllabusId: string;
    selectedParts: SelectedPart[];
    editMode: boolean;
    addPart: (pageId: number, newPart: PdfPart) => void;
    togglePartSelection: (pageNumber: number, partIndex: number) => void;
    setVersion: React.Dispatch<React.SetStateAction<number>>;
}

interface PartCommon {
    y: number;
    height: number;
}

export const Page: React.FC<PageProps> = ({
    pageNumber,
    pageIndex,
    ref,
    paper,
    pdfPage,
    paperType,
    amazonImgUrl,
    pdfId,
    syllabusId,
    selectedParts,
    editMode,
    addPart,
    togglePartSelection,
    setVersion
}) => {
    const [pageDimensions, setPageDimensions] = useState<{ width: number, height: number }>({ width: 595.276, height: 841.89 });
    const [isEditingPart, setIsEditingPart] = useState(false);

    const [isNaming, setIsNaming] = useState(false);
    const [addedPartPage, setAddedPartPage] = useState(-1);
    const [addedPartName, setAddedPartName] = useState('');
    const [partNameError, setPartNameError] = useState('');

    const [parts, setParts] = useState<PdfPart[] | MemoPdfPart[]>(pdfPage.parts);
    const [originalParts, setOriginalParts] = useState<PdfPart[] | MemoPdfPart[]>(pdfPage.parts);

    const onPageLoadSuccess = async (pageNumber: number, page: pdfjs.PDFPageProxy) => {
        const viewport = page.getViewport({ scale: 1 });
        const { width, height } = viewport;
        setPageDimensions({ width, height });
    };

    useEffect(() => {
        if(pdfPage.parts && pdfPage.parts.length) {
            setParts(pdfPage.parts);
            setOriginalParts(pdfPage.parts);
        }
    }, [pdfPage])

    const handleNameConfirm = () => {
        const exists = pdfPage.parts.some(part => part.name === addedPartName);
        if (addedPartName === '') {
            setPartNameError('Part name cannot be empty');
            return;
        }
        else if (exists) {
            setPartNameError('Part with this name already exists');
            return;
        }

        setIsNaming(false);

        var new_y = 40;
        if (pdfPage.parts.length) {
            let highest_y_part = (pdfPage.parts as PartCommon[]).reduce((prev, current) => {
                return (prev.y > current.y) ? prev : current;
            });
            new_y = highest_y_part.y + highest_y_part.height + 20;
        }

        // if new_y is near to the page height, find a gap in between the parts of the page and set new_y to that
        if (new_y > pageDimensions.height - 100) {
            let gap = 120;
            let prev_part: PartCommon | null = null;
            let possible_new_y: number[] = [];
            pdfPage.parts.forEach((part: PartCommon) => {
                if (prev_part) {
                    if (part.y - prev_part.y - prev_part.height > gap) {
                        possible_new_y.push(prev_part.y + prev_part.height + 20);
                    }
                }
                prev_part = part;
            });

            if (possible_new_y.length) {
                new_y = possible_new_y.reduce((prev, current) => {
                    return (prev > current) ? prev : current;
                }) as number;
            }
            else {
                new_y = 40;
            }
        }

        const p_type = paperType === "qp" ? "q" : "m";

        const newPart = {
            id: 100000,
            page: addedPartPage - 1,
            y: new_y,
            height: 100,
            name: addedPartName,
            url: `${amazonImgUrl}_${p_type}_${addedPartName}.jpg`,
            order: 1000,
            pdf_id: pdfId,
            image_id: -1,
            answer_image_id: -1,
            question_text: "",
            version: 1
        }
        addPart(addedPartPage, newPart);
        setAddedPartPage(-1);
        setAddedPartName('');
        setPartNameError('');
    }

    const handleAddNewPart = (pageNumber: number) => {
        setAddedPartPage(pageNumber);
        setIsNaming(true);
    }

    const handleUpdatePartName = (name: string) => {
        setAddedPartName(name);
        setPartNameError('');
    }

    const handleNameCancel = () => {
        setIsNaming(false);
        setAddedPartName('');
        setPartNameError('');
    }

    const updatePart = () => {
        setVersion(version => version + 1);
    }

    const handlePartResize = (partId: number, newHeight: number, handle: string) => {
        setParts((prevParts: PdfPart[] | MemoPdfPart[]) => {
            return prevParts.map((part: PdfPart | MemoPdfPart) => {
                if (part.id === partId) {
                    let newY = part.y;
                    if (handle === "n") {
                        newY = part.y - (newHeight - part.height);
                    }
                    return { ...part, y: newY, height: newHeight, version: part.version + 1 };
                }
                return part;
            });
        });
    }

    const handleResetPart = (partId: number) => {
        setParts((prevParts: PdfPart[] | MemoPdfPart[]) => {
            return prevParts.map((part: PdfPart | MemoPdfPart) => {
                if (part.id === partId) {
                    const originalPart = originalParts.find((originalPart: PdfPart | MemoPdfPart) => originalPart.id === partId);
                    if (originalPart) return originalPart;
                }
                return part;
            });
        });
    }

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        setParts((prevParts: PdfPart[] | MemoPdfPart[]) => {
            return prevParts.map((part: PdfPart | MemoPdfPart) => {
                if (part.id === event.active.id) {
                    if (part.y + event.delta.y > 0 && part.y + event.delta.y + part.height < pageDimensions.height) {
                        return { ...part, y: part.y + event.delta.y };
                    }
                }
                return part;
            });
        });
    }, []);


    return (
        <DndContext onDragEnd={handleDragEnd}>
            <div
                key={`page_${pageNumber}`}
                id={`page_${pageNumber}`}
                className={`pdf-page-container page_${pageNumber}`}
            >
                <div className="page-and-button-wrapper">
                    <div style={{ position: "relative" }}>
                        <ReactPage
                            key={pageIndex}
                            className="pdf-page"
                            pageNumber={pageNumber}
                            renderTextLayer={false}
                            ref={ref}
                            onLoadSuccess={(page) => onPageLoadSuccess(pageNumber, page)}
                        />
                        {parts.map((part, partIndex) => {
                            const isSelected = selectedParts.some(
                                (selectedPart) => selectedPart.part.id === part.id
                            );
                            const partNumber = isSelected ? selectedParts.findIndex((selectedPart) => selectedPart.part.id === part.id) + 1 : null;

                            return (
                                <Part
                                    part={part}
                                    key={`part_${pageNumber}_${partIndex}`}
                                    page={pageIndex}
                                    index={partIndex}
                                    pageHeight={pageDimensions?.height ?? 841.89}
                                    width={pageDimensions?.width ?? 595.276}
                                    paper={paper}
                                    pdf_id={pdfId}
                                    syllabus_id={syllabusId}
                                    isSelected={isSelected}
                                    partNumber={partNumber}
                                    editMode={editMode}
                                    isEditingPart={isEditingPart}
                                    paper_type={paperType}
                                    setIsEditingPart={setIsEditingPart}
                                    onClick={() => togglePartSelection(pageIndex, partIndex)}
                                    updatePart={updatePart}
                                    addPart={addPart}
                                    removePart={updatePart}
                                    handlePartResize={handlePartResize}
                                    handleResetPart={handleResetPart}
                                />
                            );
                        })}
                        {editMode && (<Button
                            className="add-part-button"
                            shape="circle"
                            icon={<PlusOutlined />}
                            onClick={() => handleAddNewPart(pageIndex)}
                        />)}
                    </div>
                </div>
            </div>
            <Modal title="Add part" open={isNaming} onOk={handleNameConfirm} onCancel={handleNameCancel}>
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout="horizontal">
                    <Form.Item
                        label="Part Name: "
                        validateStatus={partNameError !== '' ? "error" : ""}
                        help={partNameError}
                    >
                        <Input placeholder="Enter part name" value={addedPartName} onChange={(e) => handleUpdatePartName(e.target.value)} />
                    </Form.Item>
                </Form>
            </Modal >
        </DndContext>
    );
}