import React from 'react';
import { Modal, Typography } from 'antd';
import { format } from 'date-fns';
import { LoggedIssue } from 'interfaces';

const { Title, Text } = Typography;

interface ReasonModalProps {
  visible: boolean;
  loggedIssue: LoggedIssue[];
  onClose: () => void;
}

export const ReasonModal: React.FC<ReasonModalProps> = ({
  visible,
  loggedIssue,
  onClose,
}) => {
    const formatTime = (timeString: string) => {
        const timeParts = timeString.split(':');
        return `${timeParts[0]}:${timeParts[1]}`;
      };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
    >
      <Title level={4}>Issue Details</Title>
      {loggedIssue && loggedIssue.length > 0 && (
        <>
          {loggedIssue.map((issue, index) => (
            <div key={index} style={{ marginBottom: '1rem' }}>
              <Text strong>Date:</Text> <Text>{format(new Date(issue.date), 'd MMM yyyy')}</Text>
              <br />
              <Text strong>Time:</Text> <Text>{formatTime(issue.time)}</Text>
              <br />
              <Text strong>User:</Text> <Text>{issue.user_name}</Text>
              <br />
              <Text strong>Reason:</Text> <Text>{issue.issue_text}</Text>
            </div>
          ))}
        </>
      )}
    </Modal>
  );
};
