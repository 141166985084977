import React from "react";
import { Card, Row, Col, Typography, Button } from "antd";
import { useParams } from "react-router-dom";
import { useNavigation } from "@refinedev/core";
import "./selectAssignmentType.css";

const { Title } = Typography;

interface AssignmentOptionSelectorProps {
    courseTitle: string;
}

export const AssignmentOptionSelector: React.FC<AssignmentOptionSelectorProps> = ({ courseTitle }) => {
    const { id } = useParams<{ id: string }>();
    const { push } = useNavigation();

    const handleButtonClick = (type: "quest" | "scroll") => {
        push(`/courses/${id}/assignments/create?type=${type}`);
    };

    return (
        <div className="course-option-selector">
            <Title>{courseTitle}</Title>
            <p>Select assignment type:</p>
            <Row gutter={[16, 16]} justify="center">
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Card
                        className="course-option-card"
                        hoverable
                        title="🗡️ Quest"
                    >
                        <Button type="primary" onClick={() => handleButtonClick("quest")}>
                            Build
                        </Button>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Card
                        className="course-option-card" hoverable
                        title="📜 Scroll"
                    >
                        <Button type="primary" onClick={() => handleButtonClick("scroll")}>
                            Build
                        </Button>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

