import { useCustom, useList, useOne } from "@refinedev/core";
import { Col, Row, Typography } from "antd";
import { CountCard } from "components/hs-marking/students/CountCard";
import { StudentGrid } from "components/hs-marking/students/StudentGrid";
import { useEffect, useState } from "react";
import { ICourse, IStudent, ObserverStudents } from "interfaces";
import { useTitle } from "contexts/TitleContext";

const { Title } = Typography;

export const StudentList: React.FC = () => {
    const [assignments, setAssignments] = useState(0); 

    const { data: studentsData } = useList<ObserverStudents>({
        resource: `students`
    });

    const rawData = studentsData?.data as ObserverStudents | undefined;
    const students = rawData?.students as IStudent[] | undefined;
    const name = rawData?.observer_name as string| undefined;
    
    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle('Students');
    }, []);

    useEffect(() => {
        if (students) {
            const totalAssignments = students.reduce((total, student) => {
                const studentTotal = student.courses.reduce((studentTotal, course) => {
                    return studentTotal + course.assignments;
                }, 0);

                return total + studentTotal;
            }, 0);
            setAssignments(totalAssignments);
        }
    }, [studentsData]);
    return (
        <>
        <Row style={{ marginBottom: '24px' }} gutter={16}>
            <Col span={20}>
                <Col span={24}>
                    <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>{"Homeschool Marking"} </Title>
                </Col>
            </Col>
            <Col span={4}>
                <CountCard assignments= {assignments}/>
            </Col>
        </Row>
        <Row>
            <StudentGrid cardsData={students}/>
        </Row>
    </> 
    )
}