import React from 'react';
import Grid from '@mui/material/Grid';
import { ProgressCard } from '../SubjectCard/index';
import { SubjectCardData } from 'interfaces';
import { Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export interface ProgressGridProps {
  cardsData: SubjectCardData[];
}

export const ProgressGrid: React.FC<ProgressGridProps> = ({ cardsData }) => {
  return (
    <Grid container spacing={2} alignItems="stretch">
      {cardsData.map((data, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <ProgressCard
            id={data.id}
            total={data.total}
            complete={data.complete}
            inProgress={data.in_progress}
            needsApproval={data.needs_approval}
            issues={data.issues}
            subject={data.subject}
            revisit={data.revisit}
          />
        </Grid>
      ))}
      <Grid item xs={12} sm={6} md={4} style={{ minHeight: '288px' }}>
        <Link to={'/dashboard/department'}>
          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '95%',
              backgroundColor: '#CB6CE6',
              borderRadius: '40px',
              cursor: 'pointer',
              transition: '0.3s',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.5)',
              }
            }}
          >
            <Typography variant="h2" style={{ fontWeight: 'bold', color: "#000" }}>
              Team
            </Typography>
          </Paper>
        </Link>
      </Grid>
    </Grid>
  );
};
