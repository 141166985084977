import React from 'react';
import { ReviewContentItemDetail } from 'interfaces';

import { Col } from 'antd';

import "antd/dist/reset.css";
import { Link } from 'react-router-dom';
import { buildSheetUrl, getStatusClass } from 'utils/functions';
import moment from 'moment';
import { SortOrder } from 'antd/es/table/interface';
import { StyledButton, StyledTable } from '../../dashboards.styled';

interface ReviewTableProps {
  tableData: ReviewContentItemDetail[];
  handleActionButtonClick: (record : string, item: ReviewContentItemDetail) => void;
}

const issueReasonAbbreviations: { [key: string]: string } = {
  "Broken sheet - value errors etc.": "Brk sheet",
  "Cannot access sheet": "No access",
  "Categories are wrong": "Cat wrong",
  "Expeditions are wrong": "Exp wrong",
  "Levels are wrong": "Lvl wrong",
  "Textbooks are wrong": "Txtbk wrong",
  "Wrong sheet assigned": "Wrng sheet"
};

const getAbbreviatedValue = (issueReason: string) => issueReasonAbbreviations[issueReason] || "Other";

export const IssueTable: React.FC<ReviewTableProps> = ({
  tableData,
  handleActionButtonClick,
}) => {
  const columns = [
    {
      title: 'Issue Reason',
      dataIndex: 'issue_reason',
      key: 'issue_reason',
      sorter: (a: any, b: any) => getAbbreviatedValue(a.issue_reason).localeCompare(getAbbreviatedValue(b.issue_reason)),
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => {
        const abbreviatedText = getAbbreviatedValue(text);
        return (
          record.capture_id ?
            <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
              <div className={"section-cell connected"}>
                <span style={{ paddingLeft: '1rem' }}>{abbreviatedText}</span>
              </div>
            </Link>
            :
            <div className={"section-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{abbreviatedText}</span>
            </div>
        );
      },
    },
    {
      title: 'Content Type',
      dataIndex: 'content_type',
      key: 'content_type',
      sorter: (a: any, b: any) => a.content_type.localeCompare(b.content_type),
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => (
        record.capture_id ?
          <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"section-cell middle-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{text}</span>
            </div>
          </Link>
          :
          <div className={"section-cell middle-cell connected"}>
            <span style={{ paddingLeft: '1rem' }}>{text}</span>
          </div>
      ),
    },
    {
      title: 'Subject Level',
      dataIndex: 'subject_level',
      key: 'subject_level',
      sorter: (a: any, b: any) => a.subject_level.localeCompare(b.subject_level),
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
      render: (text: string, record: any) => (
        record.capture_id ?
          <Link to={buildSheetUrl(record.capture_id)} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"section-cell connected"}>
              <span style={{ paddingLeft: '1rem' }}>{text}</span>
            </div>
          </Link>
          :
          <div className={"section-cell connected"}>
            <span style={{ paddingLeft: '1rem' }}>{text}</span>
          </div>
      ),
    },
    {
      title: 'Assigned User',
      dataIndex: 'assigned_user',
      key: 'assigned_user',
      sorter: (a: any, b: any) => {
        const userA = a.assigned_user || '';
        const userB = b.assigned_user || '';
        return userA.localeCompare(userB);
      },
      onCell: (record: any, rowIndex: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
        onMouseEnter: () => handleMouseEnterConnected(rowIndex),
        onMouseLeave: () => handleMouseLeaveConnected(rowIndex),
      }),
    },    
    {
      title: 'Action Date',
      dataIndex: 'action_date',
      key: 'action_date',
      defaultSortOrder: 'ascend' as SortOrder,
      sorter: (a: any, b: any) => {
        const formats = ["D/M/YYYY", "D/MM/YYYY", "DD/MM/YYYY"];
        const dateA = moment(a.action_date, formats, true);
        const dateB = moment(b.action_date, formats, true);
    
        if (!dateA.isValid()) {
          return 1;
        }
        if (!dateB.isValid()) {
          return -1;
        }
        return dateA.isBefore(dateB) ? -1 : 1;
      },
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (text: string, record: any) => {
        const formats = ["D/M/YYYY", "D/MM/YYYY", "DD/MM/YYYY"];
        const date = moment(text, formats, true);
        return date.isValid() ? date.format("D MMM YYYY") : "";
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (_: string, record: any) => (
        <StyledButton
          onClick={() => handleActionButtonClick(record.subject, record)}
          type="primary"
        >
          Action
        </StyledButton>
      ),
    },
  ];

  const handleMouseEnterConnected = (rowIndex: number) => {
    const connected = document.querySelectorAll('.ant-table-row')[rowIndex].querySelectorAll('.connected');
    connected.forEach((element) => {
      element.classList.add('hover-effect');
    });
  };

  const handleMouseLeaveConnected = (rowIndex: number) => {
    const connected = document.querySelectorAll('.ant-table-row')[rowIndex].querySelectorAll('.connected');
    connected.forEach((element) => {
      element.classList.remove('hover-effect');
    });
  };

  return (
    <Col span={20}>
      <StyledTable
        borderBottomRightRadius
        borderTopRightRadius
        borderBottomLeftRadius
        borderTopLeftRadius
        $padding
        borderRadius
        paddingLeft
        columns={columns}
        dataSource={tableData}
        pagination={{ pageSize: 10 }}
      />
    </Col>
  )
}