import React from 'react';
import { DepartmentDeveloper } from 'interfaces';

import { Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { StyledTable } from './styled';

import "antd/dist/reset.css";

interface DepartmentTableProps {
    tableData: DepartmentDeveloper[];
}

export const DepartmentTable: React.FC<DepartmentTableProps> = ({ tableData }) => {
    const navigate = useNavigate();

    const columns = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            className: 'name-col black-background',
        },
        {
            title: 'In Progress',
            dataIndex: 'in_progress',
            key: 'in_progress',
            className: 'in-progress-col black-background',
            sorter: (a: DepartmentDeveloper, b: DepartmentDeveloper) => (a.in_progress ?? 0) - (b.in_progress ?? 0),
            render: (in_progress: number) => (
                <>{in_progress ?? 0}</>
            ),
        },
        {
            title: 'Overdue',
            dataIndex: 'overdue',
            key: 'overdue',
            className: 'overdue-col black-background',
            sorter: (a: DepartmentDeveloper, b: DepartmentDeveloper) => a.overdue - b.overdue,
            render: (overdue: number) => (
                <>{overdue ?? 0}</>
            ),
        },
        {
            title: 'Needs Approval',
            dataIndex: 'needs_approval',
            key: 'needs_approval',
            className: 'needs-approval-col black-background',
            sorter: (a: DepartmentDeveloper, b: DepartmentDeveloper) => a.needs_approval - b.needs_approval,
            render: (needs_approval: number) => (
                <>{needs_approval ?? 0}</>
            ),
        },
        {
            title: 'Issues',
            dataIndex: 'issues',
            key: 'issues',
            className: 'issues-col black-background',
            sorter: (a: DepartmentDeveloper, b: DepartmentDeveloper) => a.issues - b.issues,
            render: (issue: number) => (
                <>{issue ?? 0}</>
            ),
        },
        {
            title: 'Total Completed',
            dataIndex: 'total_completed',
            key: 'total_completed',
            className: 'total-completed-col black-background',
            sorter: (a: DepartmentDeveloper, b: DepartmentDeveloper) => a.total_completed - b.total_completed,
            render: (total_completed: number) => (
                <>{total_completed ?? 0}</>
            ),
        },
    ];

    return (
        <Col span={20}>
            <StyledTable
                columns={columns}
                dataSource={tableData}
                pagination={false}
                sortDirections={['descend', 'ascend']}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            navigate(`/dashboard/department/${record.id}`, {
                                state: {
                                    developer_name: record.name,
                                }
                            });
                        },
                    };
                }}
            />
        </Col>
    )
};
