export const OAUTH_STATE_KEY = 'react-use-oauth2-state-key';
export const OAUTH_RESPONSE = 'react-use-oauth2-response';
export const EXCHANGE_CODE_FOR_TOKEN_METHOD = 'POST';
export const OAUTH_AUTH_URL = 'https://saintsburg.instructure.com/login/oauth2/auth';
export const OAUTH_CLIENT_ID = '224470000000000104';
export const OAUTH_RESPONSE_TYPE = 'code';
// export const API_URL = 'http://localhost:8000/api';
export const API_URL = 'https://portal.saintsburg.com/api';
export const OAUTH_TOKEN_URL = `${API_URL}/token`;
export const AMAZON_DIR_URL = 'https://eduanywhere-images.s3.amazonaws.com/images/assignment_builder';
export const AMAZON_PDF_URL = `${AMAZON_DIR_URL}/pdf`;

export const PORTRAIT_MEMOS = [
    "9609"
]