import { Breadcrumb } from 'antd';
import styled from 'styled-components';

export const StyledBreadCrumb = styled(Breadcrumb)`
    &&.ant-breadcrumb {
        font-size: 20px;
    }

    a:hover {
        color: rgba(0, 0, 0, 1);
        background-color: rgba(0, 0, 0, 0);
    }
`;