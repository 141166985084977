import React, { useEffect, useState } from "react";
import { Modal, Button, Typography, Input, Divider, Radio, ConfigProvider } from "antd";
import { IAssignment, IRubric, ISubmission } from "interfaces";
import styles from "./AssignmentEdit.module.css";
import { RubricsCard } from "../RubricsCard";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Submission } from "../Submissions";
import { MarkingScheme } from "../MarkingScheme";

const { Text } = Typography;

interface AssignmentEditProps {
  assignment: IAssignment;
  scroll: IAssignment;
  scrollData: ISubmission | null;
  rubric: IRubric[] | null;
  submission: ISubmission | null;
  onSave: (data: IAssignment) => void;
  onClose: () => void;
}

interface IColorButtonProps {
  btnColor: string;
  btnNode: React.ReactNode;
}

export const AssignmentEdit: React.FC<AssignmentEditProps> = ({
  assignment,
  scroll,
  scrollData,
  rubric,
  submission,
  onClose,
  onSave,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [maxPoints, setMaxPoints] = useState<number>(100);
  const [view, setView] = useState<"markingScheme" | "rubric" | "submission">(
    "submission"
  );
  const [selectedRatings, setSelectedRatings] = useState<{
    [criterionId: string]: string;
  }>({});
  const [status, setStatus] = useState<"complete" | "incomplete" | null>(null);

  const formatPoints = (points: number | null): string => {
    if (points === null || points === undefined || isNaN(points)) {
      return "";
    }
    return Number.isInteger(points) ? points.toString() : points.toFixed(2);
  };

  const handlePointsUpdate = (
    points: number,
    ratings: { [criterionId: string]: string }
  ) => {
    setSelectedRatings(ratings);
    setInputValue(formatPoints(points));
  };

  useEffect(() => {
    setInputValue(formatPoints(assignment?.submission.score ?? null));
  }, [assignment?.submission.score]);

  useEffect(() => {
    setMaxPoints(assignment?.points_possible ?? 100);
  }, [assignment?.points_possible]);

  useEffect(() => {
    setView("submission");
  }, [assignment?.name]);

  useEffect(() => {
    if (assignment?.name?.toLowerCase().includes("artefact")) {
      if (assignment?.submission?.score === 5) {
        setStatus("complete");
      } else if (assignment?.submission?.score === 0) {
        setStatus("incomplete");
      } else {
        setStatus(null);
      }
    }
  }, [assignment]);

  const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d{0,2})?$/;
    if ((reg.test(value) && parseFloat(value) <= maxPoints) || value === "") {
      setInputValue(value);
    }
  };

  const handleSave = () => {
    if (assignment) {
      const updatedAssignment = {
        ...assignment,
        submission: {
          ...assignment.submission,
          score: status === "complete" ? 5 : status === "incomplete" ? 0 : parseFloat(inputValue),
        },
      };
      onSave(updatedAssignment);
    }
  };

  const ButtonLabel =
    assignment?.name?.toLowerCase().includes("marking") ||
    assignment?.name?.toLowerCase().includes("homework discussion") ||
    assignment?.name?.toLowerCase().includes("tribe council") ||
    assignment?.name?.toLowerCase().includes("myclues") ||
    assignment?.name?.toLowerCase().includes("map") ||
    assignment?.name?.toLowerCase().includes("scroll")
      ? "Rubric"
      : assignment?.name?.toLowerCase().includes("errand") ||
        assignment?.name?.toLowerCase().includes("quest") ||
        assignment?.name?.toLowerCase().includes("class test") ||
        (/\bw\d+\b/.test(assignment?.name?.toLowerCase()) && !assignment?.name?.toLowerCase().includes("marking"))
      ? "Marking Scheme"
      : "";

  const isSaveButtonDisabled =
    view === "rubric" &&
    !rubric?.every((criterion) => selectedRatings[criterion.id]);

  const isOnlySubmission = !ButtonLabel;

  const allRubricValuesSelected = rubric?.every(
    (criterion) => selectedRatings[criterion.id]
  ) ?? false;

  const inputStyle = {
    width: "80px",
    borderColor: inputValue && (view === "rubric" ? allRubricValuesSelected : true) ? "green" : "red",
  };

  const isArtefactAssignment = assignment?.name?.toLowerCase().includes("artefact");

  const handleToggle = () => {
    if (status === "complete") {
      setStatus("incomplete");
      setInputValue("0");
    } else {
      setStatus("complete");
      setInputValue("5");
    }
  };

  const ColorButton = ({ btnColor, btnNode }: IColorButtonProps) => {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: btnColor,
          },
        }}
      >
        {btnNode}
      </ConfigProvider>
    );
  };
  return (
    <Modal
      visible={true}
      title={null}
      onCancel={onClose}
      width={1200}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleSave}
          disabled={isOnlySubmission ? false : isSaveButtonDisabled}
        >
          Save
        </Button>,
      ]}
    >
      <div className={styles.flexContainer}>
        <div>
          {isOnlySubmission ? (
            <Text className={styles.submissionText} ><b>Submission</b></Text>
          ) : (
            <Radio.Group
              value={view}
              onChange={(e) => setView(e.target.value)}
            >
              <Radio.Button value="submission">Submission</Radio.Button>
              {ButtonLabel && (
                <Radio.Button value={ButtonLabel.toLowerCase().replace(" ", "")}>
                  {ButtonLabel}
                </Radio.Button>
              )}
            </Radio.Group>
          )}
        </div>
        {isArtefactAssignment ? (
          <div className={styles.statusToggle}>
            <ColorButton
                    btnColor={status === "complete" ? '#00b96b': status === "incomplete" ? '#FF5757' : 'grey'}
                    btnNode={<Button
                    onClick={handleToggle}
                        type="primary"
                    >
                        {status === "incomplete" ? "Incomplete" : "Complete"}
                    </Button>}
                />
          </div>
        ) : (
          <div className={styles.grading}>
            <Input
              className={styles.inputWithSuffix}
              value={inputValue}
              onChange={handleInputValueChange}
              style={inputStyle}
            />
            <Text> / {assignment?.points_possible ?? ""}</Text>
          </div>
        )}
      </div>
      <Divider />
      <div style={{ maxHeight: "600px", overflowY: "auto" }}>
        {view === "submission" ? (
          <Submission assignment={assignment} submission={submission} />
        ) : (
          <div>
            {ButtonLabel === "Rubric" ? (
              <RubricsCard
                rubric={rubric}
                selectedRatings={selectedRatings}
                onPointsUpdate={handlePointsUpdate}
              />
            ) : (
              <MarkingScheme scroll={scroll} submission={scrollData} />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
