import styled from "styled-components";

interface CustomTabProps {
    position: 'left' | 'right';
    isActive: boolean;
}

interface TabTextProps {
    isActive: boolean;
    overdue?: boolean;
    fontSize?: string; 
}

export const CustomTabsContainer = styled.div`
  background: black;
  display: flex;
  height: 90px;
  width: 480px;
  border-radius: 50px;
  position: relative;
`;

export const CustomTab = styled.div<CustomTabProps>`
    width: 240px; 
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    cursor: pointer;
    border-radius: ${(props) =>
        props.position === "left" ? "35px 0 0 35px" : "0 35px 35px 0"};
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;

export const TabText = styled.span<TabTextProps>`
    color: ${props => props.isActive ? (props.overdue ? '#FF3131' : '#ac7dd4') : 'grey'};
    font-size : ${props => props.fontSize ? props.fontSize : '20px'};
`;

export const VerticalDivider = styled.div`
  width: 2px;
  background: white;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 238px; /* Adjusted to fit the container width */
  z-index: 1;
`;
