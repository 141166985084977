import React, { useEffect, useState } from "react";
import { useApiUrl, useList } from "@refinedev/core";
import { AutoComplete, Button, Col, List, Row, Space, Table, Typography, message } from "antd";
import { DownloadOutlined } from '@ant-design/icons';
import { axiosInstance } from "@refinedev/simple-rest";

import { AssignmentPaper, SubjectData } from "interfaces";
import { useTitle } from "contexts/TitleContext";

const { Text, Title } = Typography;

interface PaperLink {
    key: React.Key;
    name: string;
    link: string;
}

export const AssignmentPapers: React.FC = () => {
    const apiUrl = useApiUrl();

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle('Assignment Papers');
    }, []);

    const [subjects, setSubjects] = useState<SubjectData[]>([]);
    const [subject, setSubject] = useState<SubjectData | undefined>();
    const [assignments, setAssignments] = useState<AssignmentPaper[]>([]);
    const [assignment, setAssignment] = useState<AssignmentPaper | undefined>();
    const [year, setYear] = useState<string | undefined>();
    const [links, setLinks] = useState<PaperLink[]>([]);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [loadingZip, setLoadingZip] = useState<boolean>(false);
    const [selectedLinks, setSelectedLinks] = useState<PaperLink[]>([]);

    const { data: subject_data } = useList<SubjectData>({
        resource: `admin/subjects`
    });

    const subject_list = subject_data?.data ?? [];

    useEffect(() => {
        if (subject_list) {
            console.log(subject_list)
            setSubjects(subject_list);
        }
    }, [subject_list]);

    const columns = [
        {
            title: 'Paper Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: PaperLink) => (
                <a href={record.link} target="_blank" rel="noopener noreferrer">
                    {text}
                </a>
            ),
        },
    ];

    const searchSubject = async (subject_id: string) => {
        if (!subject_id) {
            return;
        }

        setLinks([]);
        setSelectedLinks([]);
        setAssignments([]);
        try {
            const response = await axiosInstance.get(`${apiUrl}/admin/subjects/${subject_id}/assignments`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setAssignments(body);
            }

        } catch (error) {
            console.error(error);
            message.error('Failed to fetch assignments.');
            setLoadingZip(false);
        }
    };
    const handleSearch = async () => {
        if (!subject || !assignment) {
            message.error('Please select both subject and assignment.');
            return;
        }

        setLinks([]);
        setSelectedLinks([]);
        setLoadingSearch(true);
        try {
            const response = await axiosInstance.get(`${apiUrl}/admin/subjects/${subject?.id}/assignments/${assignment?.id}/paper`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.length === 0) {
                    message.error('No papers found.');
                    setLoadingSearch(false);
                    return;
                }
                setLinks(body.map((link: any, index: any) => ({
                    key: index,
                    name: link.name,
                    link: link.link,
                })));
                setLoadingSearch(false);
            }

        } catch (error) {
            console.error(error);
            message.error('Failed to fetch papers.');
            setLoadingZip(false);
            setLoadingSearch(false);
        }
    };

    const handleDownloadZip = async () => {
        if (links.length === 0) {
            message.error('No papers available to download.');
            return;
        }

        setLoadingZip(true);
        try {
            const post_data = {
                papers: selectedLinks,
                subject: subject?.name,
                year: year
            }
            const response = await axiosInstance.post(`${apiUrl}/dashboard/admin/zip`, post_data, {
                headers: {
                    "Content-Type": "application/json"
                },
                responseType: 'blob'
            });
            if (response.status === 200) {
                const now = new Date();
                const formattedDate = now
                    .toISOString()
                    .replace(/[:.]/g, '-')
                    .replace('T', '_')
                    .replace('Z', '')
                    .split('.')[0];
                const fileName = `${formattedDate}_${subject?.name}_${year}.zip`;

                const blob = new Blob([response.data], { type: 'application/zip' });
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);

                document.body.appendChild(link);
                link.click();

                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);

                setLoadingZip(false);
            }

        } catch (error) {
            console.error(error);
            message.error('Failed to download papers.');
            setLoadingZip(false);
        }
    };

    const handleSubjectSelect = async (value: any) => {
        if (value) {
            await searchSubject(value);
        }
    }

    const handleSubjectChange = async (value: any) => {
        setAssignment(undefined);
        setSubject(subjects.find(subject => subject.id === value));
    }

    const handleAssignmentChange = (value: any) => {
        setAssignment(assignments.find(assignment => assignment.id === value));
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: PaperLink[]) => {
            setSelectedLinks(selectedRows);
        },
        selectedRowKeys: selectedLinks.map((link) => link.key),
    };

    return (<>
        <Row style={{ marginBottom: '24px' }} gutter={16}>
            <Col span={24}>
                <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>Assignment Exam Papers</Title>
            </Col>
        </Row>
        <Row style={{ marginBottom: '24px' }} gutter={16}>
            <Col span={24}>
                <Space direction="vertical" size="large">
                    <Space>
                        <AutoComplete
                            style={{ width: 200 }}
                            placeholder="Select subject"
                            options={subjects.map(subject => ({ value: subject.id, label: subject.name }))}
                            onSelect={handleSubjectSelect}
                            onChange={handleSubjectChange}
                            value={subject?.name}
                            allowClear
                            filterOption={(inputValue, option: any) =>
                                option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                        <AutoComplete
                            style={{ width: 600 }}
                            placeholder="Select assignment"
                            options={assignments.map(a => ({ value: a.id, label: a.name }))}
                            onChange={handleAssignmentChange}
                            value={assignment?.name}
                            allowClear
                            filterOption={(inputValue, option: any) =>
                                option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                        />
                        <Button type="primary" onClick={handleSearch} loading={loadingSearch}>
                            Search
                        </Button>
                    </Space>

                    {links.length > 0 && (
                        <>
                            <Table
                                dataSource={links}
                                columns={columns}
                                rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection,
                                }}
                                pagination={false}
                                bordered
                                style={{ backgroundColor: 'white', width: '100%' }}
                            />
                            {selectedLinks.length > 0 && (
                                <Button
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                    onClick={handleDownloadZip}
                                    style={{ width: '200px' }}
                                    loading={loadingZip}
                                    block
                                >
                                    Download as ZIP
                                </Button>
                            )}
                        </>
                    )}
                </Space>
            </Col>
        </Row>
    </>
    );
}