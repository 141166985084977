import { HttpError, useList } from "@refinedev/core";
import { List } from "@refinedev/antd"
import { ICourse } from "interfaces";
import { CourseGrid } from "components";
import { useTitle } from "contexts/TitleContext";
import { useEffect } from "react";

export const CourseList: React.FC = () => {

    const { data } = useList<ICourse, HttpError>({
        resource: "courses"
    });

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle('Courses');
    }, []);

    const courses = data?.data ?? [];

    return (
        <List>
            <CourseGrid courses={courses} />
        </List>
    )
}