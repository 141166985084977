import React, { useState } from 'react';
import moment from 'moment';
import { Button, Col, message, TableProps } from 'antd';
import { Link } from 'react-router-dom';

import { PaperItemDetail } from 'interfaces';
import { getBoxClass, getStatusClass, statusSort } from 'utils/functions';

import { StyledButton, StyledTable } from '../../dashboards.styled';
import "antd/dist/reset.css";
import { FilterValue, SorterResult } from 'antd/es/table/interface';

interface PapersTableProps {
  tableData: PaperItemDetail[];
  handleActionButtonClick: (item: PaperItemDetail) => void;
}

export const PapersTable: React.FC<PapersTableProps> = ({
  tableData,
  handleActionButtonClick,
}) => {
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});

  const createFilter = (dataIndex: string) => {
    const uniqueValues = Array.from(new Set(tableData.map((item: any) => item[dataIndex])));
    return uniqueValues.filter(value => value != null).map(value => ({ text: value.toString(), value: value }));
  };

  const columns = [
    {
      title: 'Paper',
      dataIndex: 'paper',
      key: 'paper',
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (text: string, record: any) => (
        record.status !== "Not Started" ?
          <Link to={`/papers/${record.paper}`} style={{ color: '#000' }} target='_blank' rel='noopener noreferrer'>
            <div className={"paper-cell cell-link"}>
              <span style={{ paddingLeft: '1rem' }}>{text}
              </span>
            </div>
          </Link>
          :
          <div className={"paper-cell"}>
            <span style={{ paddingLeft: '1rem' }}>{text}
            </span>
          </div>
      ),
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a: any, b: any) => a.subject.localeCompare(b.subject),
      filters: createFilter('subject'),
      onFilter: (value: any, record: any) => record.subject === value,
      filterSearch: true,
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
    },
    {
      title: 'Assigned To',
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      sorter: (a: any, b: any) => !a.assigned_to && !b.assigned_to ? 0 : !a.assigned_to ? 1 : !b.assigned_to ? -1 : a.assigned_to.localeCompare(b.assigned_to),
      filters: createFilter('assigned_to'),
      onFilter: (value: any, record: any) => record.assigned_to === value,
      filterSearch: true,
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
    },
    {
      title: 'Due Date',
      dataIndex: 'assigned_due',
      key: 'assigned_due',
      sorter: (a: any, b: any) => {
        if (!(moment(a.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isValid()) || !(["Overdue", "In Progress"].includes(a.status))) {
          return 1;
        }
        if (!(moment(b.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isValid()) || !(["Overdue", "In Progress"].includes(b.status))) {
          return -1;
        }
        return moment(a.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true).isBefore(moment(b.assigned_due, ["D/M/YYYY", "D/MM/YYYY"], true)) ? -1 : 1;
      },
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (text: string, record: any) => (
        text && record.status !== "Complete" ? moment(text, "DD/MM/YYYY").format("D MMM YYYY") : ""
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: any, b: any) => statusSort(a.status, b.status),
      filters: createFilter('status'),
      onFilter: (value: any, record: any) => record.status === value,
      filterSearch: true,
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (text: string, record: any) => (
        <div className={getBoxClass(record)}>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      onCell: (record: any) => ({
        style: {
          backgroundColor: getStatusClass(record),
        },
      }),
      render: (_: string, record: any) => (
        <StyledButton
          onClick={() => handleActionButtonClick(record)}
          type="primary"
        >
          Action
        </StyledButton>
      ),
    },
  ];

  const handleChange = (
    _pagination: TableProps<any>['pagination'],
    filters: Record<string, FilterValue | null>,
    _sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    setFilteredInfo(filters);
  };


  const handleCopy = () => {
    const filteredData = tableData.filter((item: any) =>
      Object.keys(filteredInfo).every((key: string) =>
        filteredInfo[key] ? filteredInfo[key]?.includes(item[key]) : true
      )
    );

    const textToCopy = filteredData.map(item => item["paper"]).join('\n');
    navigator.clipboard.writeText(textToCopy).then(() => {
      message.success('Papers copied to clipboard!');
    }).catch(err => {
      message.error('Failed to copy papers to clipboard.');
    });
  };

  return (
    <Col span={20}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <StyledButton onClick={handleCopy}>Copy Output</StyledButton>
      </div>
      <StyledTable
        rowKey="id"
        columns={columns}
        dataSource={tableData}
        onChange={handleChange}
        pagination={{ pageSize: 10 }}
      />
    </Col>
  )
}
