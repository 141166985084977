import { createContext, useContext, useState } from 'react';
import { QuestionsData } from 'interfaces';

interface QuestionsDataProviderProps {
    children: React.ReactNode;
}

const QuestionsDataContext = createContext<any>(null);

export const useQuestionsData = () => useContext(QuestionsDataContext);

export const QuestionsDataProvider: React.FC<QuestionsDataProviderProps> = ({ children }) => {
    const [questionsData, setQuestionsData] = useState<QuestionsData | null>(null);

    return (
        <QuestionsDataContext.Provider value={{ questionsData, setQuestionsData }}>
            {children}
        </QuestionsDataContext.Provider>
    );
};
