import React, { useCallback } from 'react';
import {
    DndContext,
    DragEndEvent,
    closestCenter,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Space } from 'antd';
import { MinusOutlined } from '@ant-design/icons';

import { IImage } from 'interfaces';

interface DraggableListProps {
    items: IImage[];
    setItems: any;
}

const DraggableItem = (props: any) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ 
        id: props.id
    });

    const { item, removeItem } = props;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }

    return (
        <div ref={setNodeRef} {...attributes} {...listeners}>
            <div style={style}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '12px',
                        borderBottom: '1px solid #f0f0f0',
                        cursor: 'grab'
                    }}
                >
                    <div>{item.description}</div>
                    <Space >
                        <MinusOutlined
                            style={{ pointerEvents: 'auto', cursor: 'pointer' }}
                            onMouseDown={(event) => {
                                event.stopPropagation();
                                removeItem(item.id);
                            }
                            } 
                            />
                    </Space>
                </div>
            </div>
        </div>
    )
}

export const ImageList: React.FC<DraggableListProps> = ({ items, setItems }) => {
    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event;
        if (!over || active.id === over.id) return;
        setItems((prevItems: IImage[]) => {
            const activeIndex = prevItems.findIndex(item => item.id === active.id);
            const overIndex = prevItems.findIndex(item => item.id === over.id);
            return arrayMove(prevItems, activeIndex, overIndex);
        });
    }, []);

    const removeItem = useCallback((id: number) => {
        setItems((prevItems: IImage[]) => {
            return prevItems.filter(item => item.id !== id)
        });
    }, [items, setItems]);

    return (
        <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
            >
                {items.map((item) => <DraggableItem key={item.id} id={item.id} item={item} removeItem={removeItem} />)}
            </SortableContext>
        </DndContext>
    )
};