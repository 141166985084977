import React from 'react';
import { Button } from 'antd';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const pageNumbers = [];
  const maxPagesToShow = 5;
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
      {currentPage > 1 && (
        <Button onClick={() => onPageChange(1)}>{`<<`}</Button>
      )}
      {pageNumbers.map((page) => (
        <Button
          key={page}
          type={page === currentPage ? 'primary' : 'default'}
          onClick={() => onPageChange(page)}
        >
          {page}
        </Button>
      ))}
      {currentPage < totalPages && (
        <Button onClick={() => onPageChange(totalPages)}>{`>>`}</Button>
      )}
    </div>
  );
};
