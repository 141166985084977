import React, { useEffect, useState } from 'react';
import { useApiUrl } from '@refinedev/core';
import { List, Input, AutoComplete, Button, MenuProps, Dropdown, Space, Radio, Row, Tabs, TabsProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import {
    Question,
    ISection as Section,
    SectionOption,
    SelectedPart,
    ITopic as Topic,
    TopicOption,
    InputStatus
} from 'interfaces';
import { questionTabName } from './constants';
import TabPane from 'antd/es/tabs/TabPane';

const states = ['Usable', 'Not in syllabus', 'Incorrect image part']

const items = states.map((state) => {
    return {
        key: state,
        label: state
    }
});

type SelectionContentProps = {
    questions: Question[];
    currentQuestionId: number;
    section: Section;
    topic: Topic;
    questionNameStatus: string;
    questionName: string;
    mcAnswer: string;
    mcAnswerStatus: string;
    sectionStatus: string;
    topicStatus: string;
    sections: Section[];
    filteredTopics: Topic[];
    sectionInputValue: string;
    topicInputValue: string;
    selectedParts: SelectedPart[];
    memoSelectedParts: SelectedPart[];
    questionState: string;
    questionType: string;
    currentPaperKey: string;
    handleQuestionNameChange: (value: string) => void;
    handleMCAnswerChange: (value: string) => void;
    handleSelectSection: (option: SectionOption) => void;
    handleOnSectionChange: (value: string) => void;
    handleOnTopicSelect: (option: TopicOption) => void;
    setSectionInputValue: (value: string) => void;
    setTopicInputValue: (value: string) => void;
    setQuestionState: (newQuestionState: string) => void;
    setQuestionType: (newQuestionType: string) => void;
    updateStatus: (newQuestionNameStatus: InputStatus, newSectionStatus: InputStatus, newTopicStatus: InputStatus, newMCAnswerStatus: InputStatus) => void;
    insertQuestionApi: (newQuestion: Question) => Promise<number>;
    updateQuestionApi: (newQuestion: Question) => void;
    deleteQuestionApi: (questionId: number) => void;
    modifyQuestion: (newQuestions: Question[], tabName: string) => void;
    setCurrentPaperKey: (key: string) => void;
}

export const SelectionContent: React.FC<SelectionContentProps> = ({
    questions,
    currentQuestionId,
    section,
    topic,
    questionNameStatus,
    questionName,
    mcAnswer,
    mcAnswerStatus,
    sectionStatus,
    topicStatus,
    sections,
    filteredTopics,
    sectionInputValue,
    topicInputValue,
    selectedParts,
    memoSelectedParts,
    questionState,
    questionType,
    currentPaperKey,
    handleQuestionNameChange,
    handleSelectSection,
    handleOnSectionChange,
    handleOnTopicSelect,
    handleMCAnswerChange,
    setSectionInputValue,
    setTopicInputValue,
    setQuestionState,
    setQuestionType,
    updateStatus,
    modifyQuestion,
    insertQuestionApi,
    updateQuestionApi,
    deleteQuestionApi,
    setCurrentPaperKey
}) => {
    const [dropdownSize, setDropdownSize] = useState(questionState ? questionState.length * 10 + (60 - (questionState.length * 4)) : 102);

    const apiUrl = useApiUrl();

    useEffect(() => {
        setDropdownSize(questionState ? questionState.length * 10 + (60 - (questionState.length * 4)) : 102);
    }, [questionState])

    const validateInputs = () => {
        const newQuestionNameStatus = questionName === '' ? ' error' : '';
        const newSectionStatus = sectionInputValue === '' ? ' error' : '';
        const newTopicStatus = topicInputValue === '' ? ' error' : '';
        const hasQuestionParts = selectedParts.length > 0;
        const hasMemoParts = memoSelectedParts.length > 0;

        var newMCAnswerStatus: InputStatus = '';
        if(questionType === 'MC') {
            newMCAnswerStatus = mcAnswer === '' || !['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'].includes(mcAnswer) ? ' error' : '';
        }

        updateStatus(newQuestionNameStatus, newSectionStatus, newTopicStatus, newMCAnswerStatus)

        return newQuestionNameStatus === '' && newSectionStatus === '' && newTopicStatus === '' && newMCAnswerStatus === '' && hasQuestionParts;
    }

    const createQuestion = async () => {
        if (!validateInputs()) return;
        const newQuestions = [...questions];
        const existingQuestionIndex = questions.findIndex((question) => question.id === currentQuestionId);
        if (existingQuestionIndex > -1) {
            const updatedQuestion = {
                ...newQuestions[existingQuestionIndex],
                title: questionName,
                section,
                topic,
                mc_answer: mcAnswer,
                status: questionState,
                type: questionType,
                parts: selectedParts,
                memo_parts: memoSelectedParts,
            }
            updateQuestionApi(updatedQuestion);
            newQuestions.splice(existingQuestionIndex, 1, updatedQuestion);
        }
        else {
            var newQuestion = {
                id: newQuestions.length > 0 ? Math.max(...newQuestions.map(q => q.id)) + 1 : 1,
                title: questionName,
                section,
                topic,
                mc_answer: mcAnswer,
                status: questionState,
                type: questionType,
                parts: selectedParts,
                memo_parts: memoSelectedParts,
                build_status: 'In progress',
            }
            var id = await insertQuestionApi(newQuestion);
            newQuestion.id = id;
            newQuestions.push(newQuestion);
        }
        modifyQuestion(newQuestions, questionTabName);
    }

    const deleteQuestion = async () => {
        if (currentQuestionId !== -1) deleteQuestionApi(currentQuestionId);
        const newQuestions = questions.filter((question) => question.id !== currentQuestionId);
        modifyQuestion(newQuestions, questionTabName);
    };

    const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
        setQuestionState(key);
        setDropdownSize(key.length * 10 + (60 - (key.length * 4)));
    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    }

    const tabItems: TabsProps['items'] = [
        {
            key: "1",
            label: "Question",
            children: <div className="image-list-container">
                <List
                    dataSource={selectedParts}
                    renderItem={(item) => {
                        return <List.Item className="image-item">{
                            <img
                                src={`${apiUrl}/proxy?url=${item.part.url}&v=${item.part.version}`}
                                style={{
                                    width: '80%',
                                    maxWidth: '80%',
                                    maxHeight: '100%',
                                    height: 'auto',
                                    display: 'block',
                                    objectFit: 'contain',
                                }}
                            />}
                        </List.Item>
                    }}
                />
            </div>
        },
        {
            key: "2",
            label: "Answer",
            children: ["MC", "Both"].includes(questionType) ? 
                <Input
                    className={`input-field${mcAnswerStatus}`}
                    value={mcAnswer}
                    placeholder={"Enter MC answer"}
                    style={{ width: '150px' }}
                    onChange={(e) => handleMCAnswerChange(e.target.value)}
                /> :
                <div className="image-list-container">
                    <List
                        dataSource={memoSelectedParts}
                        renderItem={(item) => {
                            return <List.Item className="image-item">{
                                <img
                                    src={`${apiUrl}/proxy?url=${item.part.url}&v=${item.part.version}`}
                                    style={{
                                        width: '100%',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        height: 'auto',
                                        display: 'block',
                                        objectFit: 'contain',
                                        alignItems: 'center'
                                    }}
                                />}
                            </List.Item>
                        }}
                    />
                </div>
        }

    ]

    return (
        <div className="selection-container">
            <div className="input-fields">
                <Input
                    className={`input-field${questionNameStatus}`}
                    placeholder="Question name"
                    value={questionName}
                    onChange={(e) => handleQuestionNameChange(e.target.value)}
                />
                <AutoComplete
                    className={`auto-field${sectionStatus}`}
                    placeholder="Section"
                    options={sections.map((section) => ({ value: section.id, label: section.name }))}
                    value={sectionInputValue}
                    allowClear
                    onSelect={(_, option) => handleSelectSection(option)}
                    onChange={(value) => handleOnSectionChange(value)}
                    onSearch={setSectionInputValue}
                    filterOption={(inputValue, option: any) =>
                        option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                />
                <AutoComplete
                    className={`auto-field${topicStatus}`}
                    placeholder="Topic"
                    options={filteredTopics.map((topic) => ({ value: topic.id, label: topic.name, section_id: topic.section_id }))}
                    value={topicInputValue}
                    allowClear
                    onSelect={(_, option) => handleOnTopicSelect(option)}
                    onSearch={setTopicInputValue}
                    filterOption={(inputValue, option: any) =>
                        option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                />
                <Row align="middle" justify="space-between" style={{ width: '100%' }}>
                    <Dropdown menu={menuProps} trigger={['click']}>
                        <Button style={{ maxWidth: `${dropdownSize}px` }}>
                            <Space>
                                {questionState}
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>

                    <Radio.Group onChange={(e) => setQuestionType(e.target.value)} value={questionType}>
                        <Radio.Button value="SQ">SQ</Radio.Button>
                        <Radio.Button value="MC">MC</Radio.Button>
                        <Radio.Button value="Both">Both</Radio.Button>
                    </Radio.Group>
                </Row>

            </div>
            <Tabs activeKey={currentPaperKey} items={tabItems} onChange={setCurrentPaperKey} />
            <div className="selection-pane-footer">
                <Button type="primary" danger onClick={deleteQuestion}>
                    Delete
                </Button>
                <Button type="primary" onClick={createQuestion}>
                    Save
                </Button>
            </div>
        </div>
    )
}