import { LoginButtonProps } from "interfaces";
import { Button } from "antd";
import Icon from "@ant-design/icons";
import MessageSvg from "../../assets/canvas.svg";

export const LoginButton = (props: LoginButtonProps) => {
    return (<Button
        type="primary"
        block
        icon={<Icon component={() => (
            <img src={MessageSvg} width={20} height={20} alt='' style={{ transform: "translateY(1px)" }} />)}
            
        />}
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "8px",
        }}
        onClick={() =>
            props.getAuth()
        }
    >
        Login with Canvas
    </Button>)
}