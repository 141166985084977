import React, { useEffect, useState } from "react";
import { useApiUrl } from "@refinedev/core";
import { axiosInstance } from "@refinedev/simple-rest";
import { Col, Row, Typography } from "antd";

import { ReviewContentItemDetail, IActionObject, OperationProgress, ReviewContentOverview, User } from "interfaces";
import { ReviewTable } from "components/dashboards/admin-dashboard/ReviewTable";
import { ActionFormModal } from "components/dashboards/admin-dashboard/ActionFormModal";
import { useTitle } from "contexts/TitleContext";

import CustomTabs from "components/dashboards/admin-dashboard/Tabs";
import { IssueTable } from "components/dashboards/admin-dashboard/IssueTable";
import { IssueFormModal } from "components/dashboards/admin-dashboard/IssueFormModal";
import Notification from "components/dashboards/admin-dashboard/Notification";

const { Title } = Typography;
const abbreviation = {
    "broken_sheet" : "Broken sheet - value errors etc.",
    "no_access" : "Cannot access sheet",
    "wrong_category" : "Categories are wrong",
    "wrong_expedition" : "Expeditions are wrong",
    "wrong_level" : "Levels are wrong",
    "wrong_textbook" : "Textbooks are wrong",
    "wrong_sheet" : "Wrong sheet assigned"
  };

const client = process.env.REACT_APP_DB_CLIENT as string;
const secret = process.env.REACT_APP_DB_SECRET as string;

interface ResultData {
    status: string;
    messages: string[];
  }

export const TechnicalDashboard: React.FC = () => {
    const apiUrl = useApiUrl();
    const [version, setVersion] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [data, setData] = useState<ReviewContentOverview | null>(null);
    const [issuesData, setIssuesData] = useState<ReviewContentOverview | null>(null);
    const [open, setOpen] = useState(false);
    const [modalStage, setModalStage] = useState<string>("initial");
    const [selectedItem, setSelectedItem] = useState<ReviewContentItemDetail>({} as ReviewContentItemDetail);
    const [users, setUsers] = useState<User[]>([]);
    const [operationId, setOperationId] = useState<number>(0);
    const [operationProgress, setOperationProgress] = useState<OperationProgress>({} as OperationProgress);
    const [revisitComment, setRevisitComment] = useState<string>("");
    const [linkData, setLinkData] = useState<string>("");
    const [resultData, setResultData] = useState<ResultData | null>(null);
    
    const { setTitle } = useTitle();

    useEffect(() => {
        setTitle('Tech Dashboard');
    }, []);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [reviewResponse, issueResponse] = await Promise.all([
                    axiosInstance.get(`${apiUrl}/dashboard/admin/technical?version=${version}`, {
                        headers: { "Content-Type": "application/json" }
                    }),
                    axiosInstance.get(`${apiUrl}/dashboard/admin/issues?version=${version}`, {
                        headers: { "Content-Type": "application/json" }
                    })
                ]);

                setData(reviewResponse.data);
                setIssuesData(issueResponse.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const rawData = data;
    const tableData = rawData?.details as ReviewContentItemDetail[] | undefined ?? [];
    const users_details = rawData?.users as User[] | undefined ?? [];

    const rawIssueData = issuesData;
    const issueTableData = rawIssueData?.details as ReviewContentItemDetail[] | undefined ?? [];
    const issues_users_details = rawIssueData?.users as User[] | undefined ?? [];

    const combinedData = {
        reviewData: data,
        issueData: issuesData
    };
    const handleActionButtonClick = async (record: string, item: ReviewContentItemDetail) => {
        
        if (activeTab === 0) {
            setUsers(users_details);
        } else {
            setUsers(issues_users_details);
        }
        setSelectedItem(item);
        setModalStage("initial");
        setOpen(true);
        setRevisitComment('');

        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/subsheetlink`, item, {
                headers: { "Content-Type": "application/json" }, 
            });
            if (response.status === 200) {
                const body = response.data;
                setLinkData(body);
            }
            
        } catch (error) {
            console.error("Error fetching link data:", error);
        }
    }
    const handleModal = async (isCancel: boolean, action?: IActionObject) => {
        if (!isCancel && action) {
            
            if (action.status === "ResolveExtension") {
                await handleResolveExtensionOperation(action);
            }
            else if (action.status === "Simulation") {
                await handleSimulationOperation(action);
            }
            else if (action.status === "Toolboxes") {
                await handleToolboxesOperation(action);
            }
            else if (action.status === "Video Lists") {
                await handleVideoListsOperation(action);
            }
            else if (action.status === "AddUser") {
                await handleAddUserOperation(action);
            }
            else if (action.status === "AddDashUser") {
                await handleAddDashUserOperation(action);
            }
            else {
                await handleBeginOperation(action);
            }
        } else {
            setModalStage("initial");
            setOpen(false);
            setRevisitComment('');
        }
    }

    const handleBeginOperation = async (action: IActionObject) => {
        switch (selectedItem.issue_reason) {
            case abbreviation.wrong_category:
              if (selectedItem.content_type === "Video Lists") {
                await axiosInstance.get(`${apiUrl}/management/update/categories`, {
                        headers: {
                            "Content-Type": "application/json",
                            "client": client,
                            "secret": secret
                        }
                    });
              } else {
                console.error("Only video_list uses this right now.");
              }
              break;
            case abbreviation.wrong_textbook:
              if (selectedItem.content_type === "Textbooks" || selectedItem.content_type === "Textbook Quiz") {
                await axiosInstance.get(`${apiUrl}/management/update/textbooks`, {
                    headers: {
                        "Content-Type": "application/json",
                        "client": client,
                        "secret": secret
                    }
                });
              }
              break;
          
            default:
              console.log("Other Error");
              break;
          }
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/content`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleAddUserOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/add-drive-user`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }
            
        } catch (error) {
            console.error(error);
        }
    }

    const handleAddDashUserOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/add-dashboard-user`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    
    const handleResolveExtensionOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/resolve-extension`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }
            
        } catch (error) {
            console.error(error);
        }
    }

    const handleSimulationOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-simulation`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                setOperationProgress(body.status);
                setOperationId(body.operationId);
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status, // "fail" or "success"
                        messages: [body.result.messages] 
                      });
                }
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    const handleToolboxesOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-toolboxes`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status, // "fail" or "success"
                        messages: [body.result.messages] 
                      });
                }
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    
    const handleVideoListsOperation = async (action: IActionObject) => {
        try {
            const response = await axiosInstance.post(`${apiUrl}/dashboard/validate-videolist`, action, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            if (response.status === 200) {
                const body = response.data;
                if (body.result && body.result.messages && body.result.messages.length > 0) {
                    setResultData({
                        status: body.result.status,
                        messages: [body.result.messages] 
                      });
                }
                setOperationProgress(body.status);
                setOperationId(body.operationId);
            }
            
        } catch (error) {
            console.error(error);
        }
    }

    const getProgress = async () => {
        const response = await axiosInstance.get(`${apiUrl}/dashboard/content/${operationId}/progress`, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        setOperationProgress(response.data);
        return response;
    }

    useEffect(() => {
        if (operationId === 0) return;

        var stayOpen = true;
        const intervalId = setInterval(async () => {
            if (!stayOpen) {
                handleModalClose();
                setOperationId(0);
                setVersion(v => v + 1);
                clearInterval(intervalId);
            }
            else {
                try {
                    const response = await getProgress();
                    stayOpen = response.data.percent < 100;
                } catch (error) {
                    console.error(error);
                    setOperationId(0);
                    handleModalClose();
                    clearInterval(intervalId);
                }
            }

        }, 1500);
    }, [operationId])

    const handleModalClose = async () => {
        setModalStage("initial");
        setOperationId(0);
        setOperationProgress({} as OperationProgress);
        setOpen(false);
        setRevisitComment('');
    }

    const handleTabChange = (index: number) => {
        setActiveTab(index);
        setVersion(v => v + 1);
    };

    const closeNotification = () => {
        setResultData(null);
      };
    return (
        <>
            {resultData && (
                <Notification data={resultData} onClose={closeNotification} />
            )}
            <Row style={{ marginBottom: '24px' }} gutter={16}>
                <Col span={20}>
                    <Col span={24}>
                        <Title style={{ fontSize: '70px', fontWeight: 'bold' }}>Technical Dashboard</Title>
                    </Col>
                </Col>
                <Col span={20}>
                    <CustomTabs activeTab={activeTab} setActiveTab={setActiveTab} combinedData={combinedData} onTabChange={handleTabChange} />
                </Col>
            </Row>
            <Row>
                {activeTab === 0 ? (
                    <ReviewTable
                        tableData={tableData}
                        handleActionButtonClick={handleActionButtonClick}
                    />
                ) : (
                    <IssueTable 
                        tableData={issueTableData}
                        handleActionButtonClick={handleActionButtonClick}
                    />
                )}
                
                {(open && activeTab === 0) ?
                 (   
                    <ActionFormModal
                        stage={modalStage}
                        item={selectedItem}
                        users={users}
                        open={open}
                        operationProgress={operationProgress}
                        revisitComment={revisitComment}
                        updateStage={setModalStage}
                        handleModal={handleModal}
                        setRevisitComment={setRevisitComment}
                        
                    />
                ):(
                    <IssueFormModal
                        stage={modalStage}
                        item={selectedItem}
                        subsheetId={linkData}
                        users={users}
                        open={open}
                        operationProgress={operationProgress}
                        updateStage={setModalStage}
                        handleModal={handleModal}
                    />
                )}
            </Row>
        </>
    )
};