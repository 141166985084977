import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { ICourse } from 'interfaces';

interface StudentCardProps {
    id: number;
    student: string;
    courses: ICourse[];
}

export const StudentCard: React.FC<StudentCardProps> = ({
    id,
    student,
    courses,
}) => {
    const totalAssignments = courses.length > 0 ? courses.reduce((sum, course) => sum + (course.assignments ?? 0), 0) : 0;
    const totalSubmissions = courses.length > 0 ? courses.reduce((sum, course) => sum + (course.submissions ?? 0), 0) : 0;
    const totalOutstanding = courses.length > 0 ? courses.reduce((sum, course) => sum + (course.outstanding ?? 0), 0) : 0;
    const totalAutoGraded = courses.length > 0 ? courses.reduce((sum, course) => sum + (course.auto_graded ?? 0), 0) : 0;

    const getBorderColor = (assignments: number) => {
        return assignments === 0 ? '#7ED957' : '#FFDE59';
    };

    return (
        <Link to={`/students/${id}`}>
            <Card
                sx={{
                    minWidth: 200,
                    width: '95%',
                    p: 0,
                    backgroundColor: '#111',
                    color: 'white',
                    borderRadius: '40px',
                    transition: '0.3s',
                    '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.5)',
                    },
                    cursor: 'pointer',
                    border: `6px solid ${getBorderColor(totalAssignments)}`,
                }}
            >
                <CardContent>
                    <Typography sx={{ fontSize: 40, fontWeight: 'bold', textAlign: 'center' }}>
                        {student}
                    </Typography>
                    <Grid container sx={{ mt: 2, px: 6 }}>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FFDE59', lineHeight: '30px' }}>
                                    Needs Grading:
                                </Typography>
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FFDE59', lineHeight: '30px' }}>
                                    {totalAssignments}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#7ED957', lineHeight: '30px' }}>
                                    Graded:
                                </Typography>
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#7ED957', lineHeight: '30px' }}>
                                    {totalSubmissions}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#5CE1E6', lineHeight: '30px' }}>
                                    Auto-Graded:
                                </Typography>
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#5CE1E6', lineHeight: '30px' }}>
                                    {totalAutoGraded}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FF5757', lineHeight: '30px' }}>
                                    Missing:
                                </Typography>
                                <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FF5757', lineHeight: '30px' }}>
                                    {totalOutstanding}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Link>
    );
};