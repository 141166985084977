import React, { useEffect, useState } from 'react';
import { axiosInstance } from "@refinedev/simple-rest";
import { useApiUrl } from '@refinedev/core';
import { MaintenancePage } from './page';

interface MaintenanceResponse {
  id: number;
  is_on: boolean;
}

interface Props {
  [key: string]: any;
}

export const withMaintenanceCheck = (Component: React.ComponentType<Props>) => {
  const WrappedComponent: React.FC<Props> = (props) => {
    const [isMaintenance, setIsMaintenance] = useState(false);
    const [loading, setLoading] = useState(true);
    const apiUrl = useApiUrl();

    useEffect(() => {
      const checkMaintenanceMode = async () => {
        try {
          const response = await axiosInstance.get(`${apiUrl}/maintenance`, {
            headers: {
              "Content-Type": "application/json"
            }
          });
          const data = await response.data as MaintenanceResponse;
          setIsMaintenance(data.is_on);
        } catch (error) {
          console.error('Failed to fetch maintenance status', error);
        }
        setLoading(false);
      };

      checkMaintenanceMode();
    }, []);

    if (loading) {
      return <div>Loading...</div>;
    }

    if (isMaintenance) {
      return <MaintenancePage />;
    }

    return <Component {...props} />;
  };

  return WrappedComponent;
};
