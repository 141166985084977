import { Button, Modal, Table } from "antd";
import { SelectedPart } from "interfaces";


interface UnusedPartsModalProps {
    unusedParts: SelectedPart[];
    unusedPartsShow: boolean;
    setUnusedPartsShow: React.Dispatch<React.SetStateAction<boolean>>;
    closeAndScroll: (partId: number, paperKey: string) => void;
}

export const UnusedPartsModal: React.FC<UnusedPartsModalProps> = ({ unusedParts, unusedPartsShow, setUnusedPartsShow, closeAndScroll }) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'part',
            key: 'part',
            render: (_: any, record: SelectedPart) => {
                return record.part.name;
            }
        },
        {
            title: 'Page',
            dataIndex: 'page',
            key: 'page',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_: any, record: SelectedPart) => {
                return <Button type="primary" onClick={() => closeAndScroll(record.part.id, "q")}>Find</Button>
            }
        }
    ]

    const handleClose = () => {
        setUnusedPartsShow(false);
    }

    return <Modal title="Parts not in use" open={unusedPartsShow} onOk={handleClose} onCancel={handleClose}>
        <Table
            dataSource={unusedParts}
            columns={columns}
            pagination={{ pageSize: 10 }}
        />
    </Modal>
}
