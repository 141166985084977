import { CSSProperties } from "react";

export const antLayoutSider: CSSProperties = {
    position: "relative",
};
export const antLayoutSiderMobile: CSSProperties = {
    position: "fixed",
    height: "100vh",
    zIndex: 999,
};

export const logoStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px 24px",
    width: "100%",
    maxWidth: "100%",
    height: "auto",
};
