import { Card, Col, Row, Typography } from "antd";
import { useTitle } from "contexts/TitleContext";
import { LoginControl } from "controllers/LoginControl";
import { useEffect } from "react";

const { Title } = Typography;

export const LoginPage: React.FC = () => {
    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle('Login');
    }, []);
    
    const CardTitle = (
        <Title level={3} style={{
            textAlign: "center",
            color: "#626262",
            marginBottom: 0,
            fontSize: "24px",
            letterSpacing: "-0.04em",
            overflowWrap: "break-word",
            hyphens: "manual",
            textOverflow: "unset",
            whiteSpace: "pre-wrap",
        }}>
            Saintsburg Assignments
        </Title>
    );

    const CardContent = (
        <Card
            title={CardTitle}
            headStyle={{ borderBottom: 0 }}
            bordered={false}
            style={{
                maxWidth: "408px",
                margin: "auto",
                boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px"
            }}
        >
            {LoginControl({ context: "main" })}
        </Card>
    );

    return (
        <Row
            justify="center"
            align="middle"
            style={{
                height: "100vh",
            }}
        >
            <Col xs={22}>
                {CardContent}
            </Col>
        </Row>
    );
};