import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogin, useNotification } from "@refinedev/core";
import { axiosInstance } from "@refinedev/simple-rest";

import { objectToQuery, queryToObject } from "utils/OAuth-tools";
import { OAUTH_CLIENT_ID, OAUTH_TOKEN_URL } from "utils/constants";
import { cleanup } from 'utils/useOAuth2';

const formatExchangeCodeForTokenServerURL = (
    exchangeCodeForTokenServerURL: string,
    clientId: string,
    code: string,
    redirectUri: string,
    state: string
) => {
    const url = exchangeCodeForTokenServerURL.split('?')[0];
    const anySearchParameters = queryToObject(exchangeCodeForTokenServerURL.split('?')[1]);
    return `${url}?${objectToQuery({
        ...anySearchParameters,
        client_id: clientId,
        grant_type: 'authorization_code',
        code,
        redirect_uri: redirectUri,
        state,
    })}`;
};

export const Callback = () => {
    const [error, setError] = useState(null);
    const { open } = useNotification();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const state = params.get('state');

    const { mutate: login } = useLogin();
    const navigate = useNavigate();
    useEffect(() => {
        if (!code || !state) {
            navigate("/login", { state: { status: 401, message: "Unauthorized" } })
        }
        else {
            axiosInstance.post(
                formatExchangeCodeForTokenServerURL(
                    OAUTH_TOKEN_URL,
                    OAUTH_CLIENT_ID,
                    code,
                    `${document.location.origin}/login`,
                    state
                ),
                {
                    credentials: 'include',
                }
            ).then(response => {
                if (response.status === 403) {
                    return response.data;
                } else if (response.status !== 200) {
                    throw new Error(response.statusText);
                } else {
                    login(response.data);
                }
            }).then((unauthorizedData: any) => {
                if (unauthorizedData && unauthorizedData.message) {
                    open?.({
                        type: "error",
                        message: "Unauthorized account",
                        description: "Error",
                        undoableTimeout: 5
                    })
                    navigate("/login", { state: { status: 403, message: "Unauthorized" } });
                }
            }).catch((error) => {
                open?.({
                    type: "error",
                    message: "Unauthorized account",
                    description: "Error",
                    undoableTimeout: 5
                })
                setError(error);
            }).finally(() => {
                cleanup()
            });
        }
    }, []);


    if (error) {
        navigate("/login", { state: { status: 401, message: error || "Unauthorized" } })
        return <h1>Error</h1>
    }
    else {
        return <h1>Redirecting...</h1>
    }
}