import { IAssignment, ICourse, IStudent } from "interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { HomeOutlined, ProfileOutlined, SnippetsOutlined, StopOutlined, UserOutlined } from "@ant-design/icons";
import { useLogout, useOne } from "@refinedev/core";
import { Form, Input, Typography, Tag, Tooltip, Button, Collapse } from "antd";
import { Breadcrumb, DateField, Edit, useForm } from "@refinedev/antd";
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import styles from './AssignmentEdit.module.css';
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTitle } from "contexts/TitleContext";

const { Title, Text } = Typography;

const formatNumber = (value: number) => new Intl.NumberFormat().format(value);

export const AssignmentEdit: React.FC = () => {
    const { id, course_id, assignment_id } = useParams();
    const navigate = useNavigate();
    const { mutate: logout } = useLogout();

    const { data: student_data } = useOne<IStudent>({
        resource: `students`,
        id: id,
        errorNotification: () => {
            logout();
            return {
                message: "Please login again",
                description: "Session expired",
                type: "error"
            }
        }
    });

    const student = student_data?.data;

    const { data: course_data } = useOne<ICourse>({
        resource: `students/${id}/courses`,
        id: course_id
    });

    const course = course_data?.data;

    const { data, isLoading } = useOne<IAssignment>({
        resource: `students/${id}/courses/${course_id}/assignments`,
        id: assignment_id
    });

    const { formProps, saveButtonProps } = useForm<IAssignment>({
        action: "edit",
        resource: `students/${id}/courses/${course_id}/assignments`,
        id: assignment_id,
        redirect: false,
        successNotification: () => {
            return {
                message: "Grade successfully updated",
                description: "Success",
                type: "success"
            }
        },
        errorNotification: () => {
            return {
                message: `Something went wrong when updating the grade`,
                description: "Error",
                type: "error",
            };
        },
        onMutationSuccess: () => {
            navigate(`/students/${id}/courses/${course_id}`);
        }
    });

    const assignment = data?.data;

    const { setTitle } = useTitle();
    useEffect(() => {
        setTitle(`${assignment?.name ?? "Assignment"}`);
    }, []);

    const due_at = new Date(Date.parse(assignment?.due_at ?? ""));
    const submitted_at = new Date(Date.parse(assignment?.submission.submitted_at ?? ""));
    var statusText = "ON TIME";
    var statusColor = "success";
    var statusIcon = <CheckCircleOutlined/>;
    if (due_at < submitted_at) {
        statusText = "LATE";
        statusColor = "warning";
        statusIcon = <ExclamationCircleOutlined/>;
    }

    const [inputValue, setInputValue] = useState('');
    const [maxPoints, setMaxPoints] = useState(100);
    
    useEffect(() => {
        setInputValue(assignment?.submission.score?.toString() ?? '');
    }, [assignment?.submission.score]);

    useEffect(() => {
        setMaxPoints(assignment?.points_possible ?? 100);
    }, [assignment?.points_possible]);

    const preview_url = `https://saintsburg.instructure.com/courses/${course_id}/assignments/${assignment?.id}/submissions/${id}?preview=1`

    const BackButton = () => <Button onClick={() => navigate(-1)}>← Go back</Button>;

    const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((reg.test(inputValue) && parseInt(inputValue) <= maxPoints) || inputValue === '' ) {
          setInputValue(inputValue);
        }
    }

    return <Edit
        headerProps={{
            title: "Submission",
            subTitle: assignment?.name ?? "Assignment"
        }}
        headerButtons={() => (
            <BackButton />
        )}
        footerButtons={({ defaultButtons }) => (
            <>
                <Button type="primary" danger icon={<StopOutlined/>} onClick={() => navigate(-1)}>Cancel</Button>
                {defaultButtons}
            </>
        )}
        saveButtonProps={saveButtonProps}
        isLoading={isLoading}
        breadcrumb={<Breadcrumb breadcrumbProps={{
            items: [
                { title: <><HomeOutlined/><span>Students</span></>, href: `/students` },
                { title: <><UserOutlined/><span>{student?.name ?? "Student"}</span></>, href: `/students/${id}` },
                { title: <><ProfileOutlined/><span>{course?.name}</span></>, href: `/students/${id}/courses/${course_id}` },
                { title: <><SnippetsOutlined/><span>{assignment?.name}</span></>, href: `/students/${id}/courses/${course_id}/assignments/${assignment_id}` },
            ]
        }} />}
    >
        <Title level={5}>Submitted at</Title>
        <Tag className={styles.tag} color={statusColor} icon={statusIcon}>{statusText}</Tag>
        <Tooltip placement="right" title={`Due at ${dayjs(assignment?.due_at).format("LLL")}`}>
            <Text>
                <DateField format="LLL" value={assignment?.submission.submitted_at} />
            </Text>
        </Tooltip>

        {assignment?.description ? (
            <>
                <Title level={5}>Instructions</Title>
                <Collapse>
                    <Collapse.Panel header="View instructions" key="1">
                        <div dangerouslySetInnerHTML={{ __html: assignment?.description ?? "" }} />
                    </Collapse.Panel>
                </Collapse>
            </>
        ) : <></>}


        <Title level={5}>Submission</Title>
        <Text><a href={preview_url ?? ""} target="_blank" rel="noreferrer"><span>View</span></a></Text>

        <Title level={5}>Grade</Title>
        <Form {...formProps} layout="vertical">
            <Form.Item
                name="Grade"
                rules={[
                    { required: true }
                ]}>
                <div className={styles.flexContainer}>
                    <Input 
                        className={styles.inputWithSuffix} 
                        value={inputValue}
                        onChange={handleInputValueChange}
                    />
                    <Text> / {assignment?.points_possible ?? ""}</Text>
                </div>
            </Form.Item>
        </Form>

    </Edit >
}