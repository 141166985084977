import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { StatusColours } from 'utils/functions';

export interface ProgressChartProps {
    complete: number;
    inProgress: number;
    remaining: number;
    revisit?: number;
    tooltipEnabled: boolean;
}


const ProgressChart: React.FC<ProgressChartProps> = ({ complete, inProgress, remaining, revisit, tooltipEnabled }) => {
    var dataArray = [complete, inProgress];
    var labels = ['Complete', 'In Progress'];
    var backgroundColors = [StatusColours["complete"], StatusColours["inProgress"]];

    if(revisit) {
        dataArray.push(revisit);
        labels.push('Revisit');
        backgroundColors.push(StatusColours["revisit"]);
    }

    dataArray.push(remaining)
    labels.push('Not Started')
    backgroundColors.push(StatusColours["remaining"])

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Progress',
                data: dataArray,
                backgroundColor: backgroundColors,
                borderWidth: 0,
                cutout: '50%',
                hoverOffset: 2
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: tooltipEnabled,
            },
        },
    };
  return (
    <Doughnut data={data} options={options} />
  );
};

export default ProgressChart;
