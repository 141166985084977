import React, { useState, useEffect } from "react";
import styles from './RubricsCard.module.css';
import { IRubric } from "interfaces";

interface RubricsCardProps {
  rubric: IRubric[] | null;
  selectedRatings: { [criterionId: string]: string };
  onPointsUpdate: (points: number, ratings: { [criterionId: string]: string }) => void;
}

export const RubricsCard: React.FC<RubricsCardProps> = ({ rubric, selectedRatings: parentSelectedRatings, onPointsUpdate }) => {
  const [selectedRatings, setSelectedRatings] = useState<{ [criterionId: string]: string }>(parentSelectedRatings);

  const handleRatingSelect = (criterionId: string, ratingId: string) => {
    setSelectedRatings(prevSelectedRatings => {
      if (prevSelectedRatings[criterionId] === ratingId) {
        const updatedRatings = { ...prevSelectedRatings };
        delete updatedRatings[criterionId];
        return updatedRatings;
      }

      return {
        ...prevSelectedRatings,
        [criterionId]: ratingId,
      };
    });
  };

  const getSelectedRatingPoints = (criterionId: string): number => {
    const ratingId = selectedRatings[criterionId];
    const criterion = rubric?.find(c => c.id === criterionId);
    const rating = criterion?.ratings.find(r => r.id === ratingId);
    return rating?.points ?? 0;
  };

  const calculateTotalPoints = () => {
    return rubric?.reduce((total, criterion) => {
      return total + getSelectedRatingPoints(criterion.id);
    }, 0) ?? 0;
  };

  useEffect(() => {
    const totalPoints = calculateTotalPoints();
    onPointsUpdate(totalPoints, selectedRatings);
  }, [selectedRatings, rubric, onPointsUpdate]);

  useEffect(() => {
    setSelectedRatings(parentSelectedRatings);
  }, [parentSelectedRatings]);

  if (!rubric || rubric.length === 0) {
    return (
      <div className={styles.noRubric}>
        <p>Rubric not found!</p>
      </div>
    );
  }

  return (
    <div className={styles.rubricsCard}>
      {rubric.map(criterion => (
        <div key={criterion.id} className={styles.criterion}>
          <div className={styles.columnsContainer}>
            <div className={styles.descriptionColumn}>
              <h3>{criterion.description}</h3>
              <p>{criterion.long_description}</p>
            </div>

            <div className={styles.ratingsColumn}>
              {criterion.ratings.map(rating => (
                <div
                  key={rating.id}
                  className={`${styles.rating} ${selectedRatings[criterion.id] === rating.id ? styles.selected : ''}`}
                  onClick={() => handleRatingSelect(criterion.id, rating.id!)}
                >
                  <p><strong>{rating.points} Points</strong></p>
                  <p>{rating.description}:</p>
                  <p>{rating.long_description}</p>
                </div>
              ))}
            </div>

            <div className={styles.pointsColumn}>
              <p>{criterion.points} Pts</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
