import React, { useState } from 'react';
import { Document, pdfjs } from 'react-pdf';
import { useApiUrl } from '@refinedev/core';
import { Spin } from 'antd';
import { PdfPage, PdfPart, SelectedPart } from 'interfaces';
import './viewer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { AMAZON_DIR_URL } from 'utils/constants';
import { Page } from '../page/page';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

type PdfViewerProps = {
  url: string;
  paper: string;
  pdf_id: number;
  syllabus_id: string | null;
  pages: PdfPage[];
  selectedParts: SelectedPart[];
  editMode: boolean;
  contentRefs: any;
  paper_type: string;
  setSelectedParts: (parts: any) => void;
  setVersion: React.Dispatch<React.SetStateAction<number>>;
  setPages: React.Dispatch<React.SetStateAction<PdfPage[]>>;
}


export const PdfViewer: React.FC<PdfViewerProps> = ({
  url,
  paper,
  pdf_id,
  pages,
  syllabus_id,
  selectedParts,
  editMode,
  paper_type,
  contentRefs,
  setSelectedParts,
  setVersion,
  setPages
}) => {
  const apiUrl = useApiUrl();
  const PDF_PROXY_URL = `${apiUrl}/proxy?url=${url}`;
  const AMAZON_IMG_URL = `${AMAZON_DIR_URL}/${syllabus_id}/${paper}`;

  const [numPages, setNumPages] = useState<number | null>(null);
  const [loadingPdf, setLoadingPdf] = useState(true);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setLoadingPdf(false);
  };

  const togglePartSelection = (pageNumber: number, partIndex: number) => {
    setSelectedParts((prevSelectedParts: SelectedPart[]) => {
      const part = pages.find((page) => page.page === pageNumber)?.parts[partIndex];
      if (!part) {
        return prevSelectedParts;
      }

      const existingPartIndex = prevSelectedParts.findIndex(
        (selectedPart: SelectedPart) => selectedPart.part.id === part.id
      );

      if (existingPartIndex >= 0) {
        const updatedSelectedParts = [...prevSelectedParts];
        updatedSelectedParts.splice(existingPartIndex, 1);
        return updatedSelectedParts;
      } else {
        return [...prevSelectedParts, { page: pageNumber, part }];
      }
    });
  };

  const addPart = (pageId: number, newPart: PdfPart) => {
    setPages((prevPages: PdfPage[]) => {
      const updatedPages = [...prevPages];
      const page = updatedPages.find((page) => page.page === pageId);

      if (!page) {
        const new_page = {
          page: pageId,
          parts: [newPart]
        };
        updatedPages.push(new_page);
      } else {
        updatedPages.find((page) => page.page === pageId)?.parts.push(newPart);
      }

      return updatedPages;
    });
  }


  if (!syllabus_id) {
    return <Spin />
  }

  return (
    <div className="pdf-viewer">
      <Document file={PDF_PROXY_URL} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages || 0), (_, pageIndex) => {
          const pageNumber = pageIndex + 1;
          const pdf_page: PdfPage = pages.find(page => page.page === pageIndex) || { page: -1, parts: [] };
          const ref = contentRefs[pageIndex];
          return (
            <Page
              pageNumber={pageNumber}
              pageIndex={pageIndex}
              ref={ref}
              paper={paper}
              pdfPage={pdf_page}
              paperType={paper_type}
              amazonImgUrl={AMAZON_IMG_URL}
              pdfId={pdf_id}
              syllabusId={syllabus_id}
              selectedParts={selectedParts}
              editMode={editMode}
              addPart={addPart}
              togglePartSelection={togglePartSelection}
              setVersion={setVersion}
            />
          );
        })}
      </Document>
    </div>
  );
}