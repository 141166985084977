import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { CourseCard } from '../CourseCard/index';
import { ICourse, IStudent } from 'interfaces';

export interface CoursesGridProps {
  cardsData: ICourse[];
}

export const CoursesGrid: React.FC<CoursesGridProps> = ({ cardsData }) => {
  
  return (
    <Grid container spacing={2} alignItems="stretch">
      {cardsData?.map((course, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <CourseCard
          id={course.id}
          name ={course.name}
          grade = {course.grade}
          submissions ={course.submissions}
          assignments= {course.assignments}
          outstanding = {course.outstanding}
          auto_graded = {course.auto_graded}
          />
        </Grid>
        ))}
    </Grid>
  );
};
