export const default_section = {
    id: -1,
    name: '',
};

export const default_topic = {
    id: -1,
    name: '',
    section_id: -1,
};

export const blankQuestion = {
    id: -1,
    title: 'new',
    section: default_section,
    topic: default_topic,
    status: 'Usable',
    type: 'SQ',
    parts: [],
    memo_parts: [],
    build_status: 'In progress',
    mc_answer: '',
}

export const questionTabName = 'questions';
export const selectionTabName = 'selection';