import { LoginButton } from "components";
import { LoginButtonProps, LoginControlProps } from "interfaces";
import { OAUTH_AUTH_URL, OAUTH_CLIENT_ID, OAUTH_RESPONSE_TYPE, EXCHANGE_CODE_FOR_TOKEN_METHOD, OAUTH_TOKEN_URL } from "utils/constants";
import { useOAuth2 } from "utils/useOAuth2";

export const LoginControl = (props: LoginControlProps) => {
    const { loading, error, getAuth } = useOAuth2({
        authorizeUrl: OAUTH_AUTH_URL,
        clientId: OAUTH_CLIENT_ID,
        redirectUri: `${document.location.origin}/auth/callback`,
        scope: "",
        responseType: OAUTH_RESPONSE_TYPE,
        exchangeCodeForTokenServerURL: OAUTH_TOKEN_URL,
        exchangeCodeForTokenMethod: EXCHANGE_CODE_FOR_TOKEN_METHOD,
        onSuccess: () => { },
        onError: (error_) => console.log("Error", error_)
    });

    const btnProps: LoginButtonProps = {
        error: error,
        loading: loading,
        context: props.context,
        getAuth: getAuth
    }
    return LoginButton(btnProps)
}