import { useCallback, useRef, useState } from 'react';
import { OAUTH_STATE_KEY } from './constants';
import { objectToQuery } from './OAuth-tools';
import { LoggedInUser, OAuth2Props } from 'interfaces';

const enhanceAuthorizeUrl = (
  authorizeUrl: string,
  clientId: string,
  redirectUri: string,
  scope: string,
  state: string,
  responseType: OAuth2Props['responseType'],
  extraQueryParametersRef: React.MutableRefObject<OAuth2Props['extraQueryParameters']>
) => {
  const query = objectToQuery({
    response_type: responseType,
    client_id: clientId,
    redirect_uri: redirectUri,
    scope,
    state,
    ...extraQueryParametersRef.current,
  });

  return `${authorizeUrl}?${query}`;
};

const generateState = (): string => {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(40) as any;
  window.crypto.getRandomValues(array);
  array = array.map((x: number) => validChars.codePointAt(x % validChars.length));
  const randomState = String.fromCharCode.apply(null, array);
  return randomState;
};

const saveState = (state: string) => {
  sessionStorage.setItem(OAUTH_STATE_KEY, state);
};

const removeState = () => {
  sessionStorage.removeItem(OAUTH_STATE_KEY);
};

export const cleanup = () => {
  removeState();
};

export const useOAuth2 = <TData = LoggedInUser>(props: OAuth2Props<TData>) => {
  const {
    authorizeUrl,
    clientId,
    redirectUri,
    scope = '',
    responseType,
    extraQueryParameters = {},
  } = props;

  const extraQueryParametersRef = useRef(extraQueryParameters);
  const [{ loading, error }] = useState({ loading: false, error: null });

  const getAuth = useCallback(() => {
    const state = generateState();
    saveState(state);

    window.location.href = enhanceAuthorizeUrl(
      authorizeUrl,
      clientId,
      redirectUri,
      scope,
      state,
      responseType,
      extraQueryParametersRef
    )

    return () => {
    };
  }, [
    authorizeUrl,
    clientId,
    redirectUri,
    scope,
    responseType
  ]);

  return { loading, error, getAuth };
}