import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ICourse } from 'interfaces';

interface CourseCardProps {
  id: number;
  name: string;
  grade: number | null;
  assignments: number;
  submissions: number;
  outstanding: number;
  auto_graded: number;
}

export const CourseCard: React.FC<CourseCardProps> = ({
  id,
  name,
  grade,
  submissions,
  assignments,
  outstanding,
  auto_graded,
}) => {
  const getBorderColor = (assignments: number) => {
    return assignments === 0 ? '#7ED957' : '#FFDE59';
  };

  const { id: studentId } = useParams();
  return (
    
    <>
    <Link to={`/students/${studentId}/courses/${id}`}>
      <Card
        sx={{
          minWidth: 200,
          width: '95%',
          p: 0,
          backgroundColor: '#111',
          color: 'white',
          borderRadius: '40px',
          transition: '0.3s',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.5)',
          },
          cursor: 'pointer',
          border: `6px solid ${getBorderColor(assignments)}`,
        }}
      >
        <CardContent>
            <Typography sx={{ fontSize: 40, fontWeight: 'bold', textAlign: 'center' }}>
                {name}
            </Typography>
            <Grid container sx={{ mt: 2, px: 6 }}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FFDE59', lineHeight: '30px' }}>
                            Needs Grading:
                        </Typography>
                        <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FFDE59', lineHeight: '30px' }}>
                            {assignments}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#7ED957', lineHeight: '30px' }}>
                            Graded:
                        </Typography>
                        <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#7ED957', lineHeight: '30px' }}>
                            {submissions}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#5CE1E6', lineHeight: '30px' }}>
                            Auto-Graded:
                        </Typography>
                        <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#5CE1E6', lineHeight: '30px' }}>
                            {auto_graded}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FF5757', lineHeight: '30px' }}>
                            Missing:
                        </Typography>
                        <Typography sx={{ fontSize: 28, fontWeight: 'bold', color: '#FF5757', lineHeight: '30px' }}>
                            {outstanding}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </CardContent>
      </Card>
     
    </Link>
  </>
  );
};
