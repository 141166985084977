import React, { useCallback, useState } from 'react';
import { Button, Modal, Input, message } from 'antd';
import { useApiUrl } from '@refinedev/core';
import { ArrowsAltOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, DragOutlined, ExclamationCircleOutlined, ScissorOutlined } from '@ant-design/icons';
import { axiosInstance } from "@refinedev/simple-rest";
import { useDraggable } from '@dnd-kit/core';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import { MemoPdfPart, PdfPart } from 'interfaces';
import { Rnd } from 'react-rnd';

import 'react-resizable/css/styles.css';

import './part.css';

const { confirm } = Modal;

interface PdfPartProps {
    part: PdfPart | MemoPdfPart;
    width: number;
    page: number;
    pageHeight: number;
    index: number;
    paper: string;
    pdf_id: number;
    syllabus_id: string;
    isSelected: boolean;
    partNumber: number | null;
    editMode: boolean;
    isEditingPart: boolean;
    paper_type: string;
    setIsEditingPart: (isEditingPart: boolean) => void;
    onClick: () => void;
    updatePart: () => void;
    addPart: (page_id: number, part: any) => void;
    removePart: (page_id: number, part_id: number) => void;
    handlePartResize: (partId: number, newHeight: number, handle: string) => void;
    handleResetPart: (partId: number) => void;
}

export const Part: React.FC<PdfPartProps> = ({
    part,
    width,
    page,
    index,
    pageHeight,
    paper,
    pdf_id,
    syllabus_id,
    isSelected,
    partNumber,
    editMode,
    isEditingPart,
    paper_type,
    setIsEditingPart,
    onClick,
    updatePart,
    addPart,
    removePart,
    handlePartResize,
    handleResetPart
}) => {
    const { id, name, y, height, image_id, url } = part;

    const [isHovered, setIsHovered] = useState(false);
    const [isResizing, setResizing] = useState(false);
    const [isSplitting, setSplitting] = useState(false);

    const [splitLinePos, setSplitLinePos] = useState(y / 2);
    const [originalSplitLinePos, setOriginalSplitLinePos] = useState(height / 2);

    const [isNaming, setIsNaming] = useState(false);
    const [partName1, setPartName1] = useState('');
    const [partName2, setPartName2] = useState('');

    const apiUrl = useApiUrl();

    const handleBeginResizing = () => {
        setIsEditingPart(!isEditingPart);
        setResizing(true);
    }

    const handleBeginSplitting = () => {
        setIsEditingPart(!isEditingPart);
        setSplitting(true);
    }

    const handleBeginRemoving = () => {
        confirm({
            title: 'Are you sure you want to remove this part?',
            icon: <ExclamationCircleOutlined />,
            onOk: async () => {
                try {
                    if (id !== 100000) {
                        const response = await axiosInstance.post(`${apiUrl}/part/${id}/delete`, { paper_type }, {
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });
                        const msg = response.data?.message;
                        message.open({
                            content: msg,
                            duration: 5,
                            type: response.status === 200 ? "success" : "error"
                        });
                    }

                    removePart(page, id);
                } catch (error) {
                    message.error('An error occurred while removing the part');
                }
            }
        })
    }

    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: part.id,
        disabled: !isResizing,
    });

    const confirmResize = async () => {
        if(y < 0 || y + height > pageHeight) {
            message.error('Part cannot be resized outside the page');
            handleResetPart(id);
            return;
        }
        const resizeData = {
            name,
            top: y,
            y: y,
            height: height,
            url,
            page,
            pdf_id,
            syllabus_id,
            paper,
            paper_type
        }
        try {
            const response = await axiosInstance.post(`${apiUrl}/part/${id}/resize`, resizeData, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const msg = response.data?.message;
            message.open({
                content: msg,
                duration: 5,
                type: response.status === 200 ? "success" : "error"
            });

            const new_part = response.data?.data as PdfPart;
            updatePart();
        } catch (error) {
            message.error('An error occurred while resizing the part');
        }
        setResizing(false);
        setIsEditingPart(false);
    }

    const cancelResize = () => {
        handleResetPart(id);
        setResizing(false);
        setIsEditingPart(false);
    };

    const handleSplitLineDrag = (e: any, ui: any) => {
        setSplitLinePos(ui.y);
    };

    const confirmSplit = () => {
        setOriginalSplitLinePos(splitLinePos);
        setSplitting(false);
        setIsNaming(true);
    }

    const cancelSplit = () => {
        setSplitLinePos(originalSplitLinePos);
        setSplitting(false);
        setIsEditingPart(false);
    };

    const handleNameConfirm = async () => {
        const splitData = {
            original_name: name,
            original_height: height,
            syllabus_id: syllabus_id,
            paper: paper,
            pdf_id: pdf_id,
            image_id: image_id,
            paper_type,
            part_1: {
                name: partName1,
                y: y,
                height: splitLinePos - 1
            },
            part_2: {
                name: partName2,
                page: page,
                y: y + splitLinePos + 1,
                height: height - splitLinePos
            }
        }
        try {
            const response = await axiosInstance.post(`${apiUrl}/part/${id}/split`, splitData, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const msg = response.data?.message;
            message.open({
                content: msg,
                duration: 5,
                type: response.status === 200 ? "success" : "error"
            });

            const { part_1, part_2 } = response.data?.data;
            updatePart();
            // addPart(page, part_2);
        } catch (error) {
            message.error('An error occurred while splitting the part');
        }

        setIsNaming(false);
        setPartName1('');
        setPartName2('');
        setIsEditingPart(false);
    };

    const handleNameCancel = () => {
        setIsNaming(false);
        setPartName1('');
        setPartName2('');
        setIsEditingPart(false);
    }

    const handleResize = useCallback(
        (e: React.SyntheticEvent, { size, handle }: ResizeCallbackData) => {
            handlePartResize(id, size.height, handle);
        },
        [id]
    );

    const style: React.CSSProperties = {
        position: 'absolute',
        top: y + (transform ? transform.y : 0),
        cursor: isResizing ? 'move' : 'default',
    };

    return <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
    >
        {editMode ? (
            isSplitting ? (
                <div
                    className={`pdf-part displayed${isHovered ? '-hovered' : ''}`}
                    style={{
                        left: 0,
                        top: y,
                        width: width,
                        height: height,
                        zIndex: 2,
                    }}
                >
                    <div className="pdf-part__container">
                        <Rnd
                            className="pdf-part__split-container"
                            size={{ width: width, height: 5 }}
                            position={{ x: 0, y: splitLinePos }}
                            enableResizing={{}}
                            bounds='parent'
                            onDragStop={handleSplitLineDrag}
                            dragAxis='y'
                        >
                            <div
                                className="pdf-part__split-line"
                            />
                            <div className="drag-icon">
                                <DragOutlined />
                            </div>
                            <div className="pdf-part__split-buttons">
                                <Button shape="circle" icon={<CheckOutlined />} onClick={confirmSplit} />
                                <Button shape="circle" icon={<CloseOutlined />} onClick={cancelSplit} />
                            </div>
                        </Rnd>
                    </div>
                </div>
            ) : (
                <ResizableBox
                    axis="y"
                    height={height}
                    width={width}
                    onResizeStop={handleResize}
                    resizeHandles={isResizing ? ['s'] : []}
                    draggableOpts={{ disabled: !isResizing }}
                    minConstraints={[0, 10]}
                    maxConstraints={[Infinity, pageHeight-10]}
                    className={`pdf-part displayed${isHovered || isResizing ? '-hovered' : ''}`}
                    style={style}
                >
                    <div style={{ width: '100%', height: '100%' }}>
                        <div ref={setNodeRef} {...(isResizing ? listeners : {})} {...attributes} style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} />

                        {isResizing && (
                            <div className="pdf-part__resize-buttons" style={{ position: 'absolute', width: '10%', left: '5%' }}>
                                <Button shape="circle" icon={<CheckOutlined />} onClick={confirmResize} />
                                <Button shape="circle" icon={<CloseOutlined />} onClick={cancelResize} />
                            </div>
                        )}

                        {isHovered && !isResizing && !isSplitting && !isEditingPart && (
                            <div className="pdf-part__hover-buttons">
                                <Button shape="circle" icon={<ArrowsAltOutlined />} onClick={handleBeginResizing} />
                                <Button shape="circle" icon={<ScissorOutlined />} onClick={handleBeginSplitting} />
                                <Button shape="circle" icon={<DeleteOutlined />} onClick={handleBeginRemoving} />
                            </div>
                        )}
                    </div>
                </ResizableBox>
            )
        ) : (
            <div
                id={`${paper_type === 'qp' ? 'q' : 'm'}_${id}`}
                className={`pdf-part default${isSelected ? ' selected' : ''}${isHovered ? ' hovered' : ''}`}
                onClick={onClick}
                style={{
                    left: 0,
                    top: y,
                    width: width,
                    height: height,
                }}
            >
                <div className={`plus-button${isSelected ? ' selected' : ''}`}>
                    {isSelected ? (
                        <span className="part-number">{partNumber}</span>
                    ) : (
                        isHovered && <span className="plus-icon">+</span>
                    )}
                </div>
            </div>
        )}
        <Modal open={isNaming} onOk={handleNameConfirm} onCancel={handleNameCancel}>
            <Input placeholder="Part 1 Name" value={partName1} onChange={(e) => setPartName1(e.target.value)} />
            <Input placeholder="Part 2 Name" value={partName2} onChange={(e) => setPartName2(e.target.value)} />
        </Modal >
    </div>
}

