// MaintenancePage.js
import { useTitle } from 'contexts/TitleContext';
import React, { useEffect } from 'react';

export const MaintenancePage: React.FC = () => {
  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle("Maintenance ongoing");
  }, []);
  return (
    <div>
      <h1>We are currently undergoing maintenance</h1>
      <p>We apologize for the inconvenience. Please check back later.</p>
    </div>
  );
};
