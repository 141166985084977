import { Button, Modal } from 'antd';
import styled from 'styled-components';

export const FormButton = styled(Button)`
    &&.ant-btn {
        width: 50%;
        border-radius: 15px;
    }
`;

export const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;

export const ProgressContains = styled.div`
    text-align: center;
`;

interface ProgressMessageProps {
    $in: boolean;
}

export const ProgressMessage = styled.p<ProgressMessageProps>`
    transition: opacity 0.3s ease-in-out;
    opacity: ${props => (props.$in ? 1 : 0)};
    margin-top: 10px;
`;
