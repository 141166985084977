
import { Button, Col, ConfigProvider, DatePicker, Divider, Form, Input, InputRef, Progress, ProgressProps, Row, Select, Space } from "antd";
import { SolutionOutlined, CheckOutlined, PauseOutlined, IdcardOutlined, WarningOutlined, PlusOutlined, StopOutlined } from '@ant-design/icons';
import React, { useRef, useState } from "react";
import { ContentItemDetail, Developer, DeveloperContentItemDetail, IActionObject, OperationProgress } from "interfaces";
import dayjs from "dayjs";
import { CSSTransition } from 'react-transition-group';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { FormButton, ProgressContains, ProgressMessage, StyledModal } from "./styled";

dayjs.extend(customParseFormat);

interface IFormModalProps {
    stage: string;
    item: ContentItemDetail | DeveloperContentItemDetail;
    users: Developer[];
    open: boolean;
    operationProgress: OperationProgress;
    issueReason: string;
    updateStage: React.Dispatch<React.SetStateAction<string>>;
    handleModal: (isCancel: boolean, action?: IActionObject) => void;
    setIssueReason: React.Dispatch<React.SetStateAction<string>>;
}

interface IInitialStageProps {
    item: ContentItemDetail | DeveloperContentItemDetail;
    handleButtonClick: (clickAction: string, nextStage?: string) => void;
}

interface IAssignStageProps {
    users: Developer[];
    setDueDate: React.Dispatch<React.SetStateAction<string>>;
    setUser: React.Dispatch<React.SetStateAction<number>>;
}

interface IIssueStageProps {
    issueReason: string;
    setIssueReason: React.Dispatch<React.SetStateAction<string>>;
}

interface IProgressProps {
    operationProgress: OperationProgress;
}

interface IPostProps {
    operationProgress: OperationProgress;
}

interface IColorButtonProps {
    btnColor: string;
    btnNode: React.ReactNode;
}

const issue_reasons = [
    "Broken sheet - value errors etc.",
    "Cannot access sheet",
    "Categories are wrong",
    "Expeditions are wrong",
    "Levels are wrong",
    "Textbooks are wrong",
    "Wrong sheet assigned",
]


const ColorButton = ({ btnColor, btnNode }: IColorButtonProps) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: btnColor,
                },
            }}
        >
            {btnNode}
        </ConfigProvider>
    )

}
const InitialStage = ({ item, handleButtonClick }: IInitialStageProps) => {
    const disabledRules = {
        "Assign": false,
        "Update": item.status !== 'Complete',
        "Approve": !(['Needs Approval', 'In Progress', 'Reassigned', 'Overdue'].includes(item.status)),
        "Unassign": ['Not Started', 'Not Needed', 'On Hold'].includes(item.status),
        "Issue": ['Issue Reported', 'Not Started', 'Not Needed'].includes(item.status),
        "Set N/A": ['Not Needed', 'Not Required'].includes(item.status),
    }
    return (
        <Row gutter={[8, 16]}>
            <Row gutter={[0, 0]} style={{ width: '100%' }}>
                <Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
                    <FormButton
                        icon={<SolutionOutlined />}
                        onClick={() => handleButtonClick('Assign', 'assign')}
                        type="primary"
                        disabled={disabledRules['Assign']}
                        style={{ width: '80%' }}
                    >
                        Assign
                    </FormButton>
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                    <ColorButton
                        btnColor={'#f57c00'}
                        btnNode={<FormButton
                            icon={<IdcardOutlined />}
                            onClick={() => handleButtonClick('Update', 'assign')}
                            type="primary"
                            disabled={disabledRules['Update']}
                            style={{ width: '80%' }}
                        >
                            Update
                        </FormButton>}
                    />
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'start' }}>
                    <ColorButton
                        btnColor={'#00b96b'}
                        btnNode={<FormButton
                            icon={<CheckOutlined />}
                            onClick={() => handleButtonClick('Approve', 'process')}
                            type="primary"
                            disabled={disabledRules['Approve']}
                            style={{ width: '80%' }}
                        >
                            Approve
                        </FormButton>}
                    />
                </Col>
            </Row>
            <Row gutter={[0, 0]} style={{ width: '100%' }}>
                <Col span={8} style={{ display: 'flex', justifyContent: 'end' }}>
                    <ColorButton
                        btnColor={'#ffbd59'}
                        btnNode={<FormButton
                            icon={<PauseOutlined />}
                            onClick={() => handleButtonClick('Unassign', 'process')}
                            type="primary"
                            disabled={disabledRules['Unassign']}
                            style={{ width: '80%' }}
                        >
                            Unassign
                        </FormButton>}
                    />
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>

                    <FormButton
                        icon={<WarningOutlined />}
                        onClick={() => handleButtonClick('Issue', 'report_issue')}
                        type="primary"
                        disabled={disabledRules['Issue']}
                        style={{ width: '80%' }}
                        danger
                    >
                        Issue
                    </FormButton>
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'start' }}>

                    <ColorButton
                        btnColor={'#858585'}
                        btnNode={<FormButton
                            icon={<StopOutlined />}
                            onClick={() => handleButtonClick('Set N/A', 'process')}
                            type="primary"
                            disabled={disabledRules['Set N/A']}
                            style={{ width: '80%' }}
                        >
                            Set N/A
                        </FormButton>}
                    />
                </Col>
            </Row>

        </Row >
    )

}

const AssignStage = ({ users, setDueDate, setUser }: IAssignStageProps) => {
    const dateFormat = 'DD-MM-YYYY';
    return (
        <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
        >
            <Form.Item label="Assign to" rules={[{ required: true, message: "Please select a user" }]}>
                <Select onChange={(e) => setUser(e)}>
                    {users?.map((ele) => {
                        return <Select.Option value={ele.id} key={`${ele.name}-${ele.id}`}>{ele.name}</Select.Option>
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Due date"
                rules={[
                    {
                        validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error('Please select a due date')),
                    },
                ]}>
                <DatePicker
                    placeholder="Select due date"
                    onChange={(date: any) => setDueDate(date.format("DD/M/YYYY"))}
                    format={dateFormat}
                    minDate={dayjs()}
                />
            </Form.Item>
        </Form>
    )
}

const IssueStage = ({ issueReason, setIssueReason }: IIssueStageProps) => {
    const [items, setItems] = useState<string[]>(issue_reasons);
    const inputRef = useRef<InputRef>(null);
    var index = 0;

    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        setItems([...items, issueReason || `New item ${index++}`]);
        setIssueReason('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const onIssueReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIssueReason(e.target.value);
    }

    return (
        <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
        >
            <Form.Item label="Select issue">
                <Select onChange={(e) => setIssueReason(e)}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space style={{ padding: '0 8px 4px' }}>
                                <Input
                                    placeholder="Other reason"
                                    ref={inputRef}
                                    value={issueReason}
                                    onChange={onIssueReasonChange}
                                    onKeyDown={(e) => e.stopPropagation()}
                                />
                                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                    Add item
                                </Button>
                            </Space>
                        </>
                    )}
                    options={items.map((item) => ({ label: item, value: item }))} />
            </Form.Item>
        </Form>
    )
}

const ProgressStage = ({ operationProgress }: IProgressProps) => {
    const twoColors: ProgressProps['strokeColor'] = {
        '0%': '#108ee9',
        '100%': '#87d068',
    };

    return (
        <ProgressContains>
            <Progress
                percent={operationProgress.percent ?? 0}
                status="active"
                showInfo={true}
                strokeColor={twoColors}
            />
            <CSSTransition
                in={!!operationProgress.message}
                timeout={300}
                classNames="message"
                unmountOnExit
            >
                {state => (
                    <ProgressMessage $in={state === 'entered'}>
                        {operationProgress.message ?? ""}
                    </ProgressMessage>
                )}
            </CSSTransition>
        </ProgressContains>
    )
}

const PostStage = ({ operationProgress }: IPostProps) => {

    return (
        <div style={{ textAlign: 'center' }}>
            <p style={{ marginBottom: '10px' }}>
                This content type requires you to execute setup functions within the capture sheet.
                Please open the sheet and execute the functions.
            </p>
            <ColorButton
                btnColor={'#00b96b'}
                btnNode={<Button
                    type="primary"
                    style={{ borderRadius: '15px' }}
                    onClick={() => window.open(`https://docs.google.com/spreadsheets/d/${operationProgress.captureId}`, '_blank', 'noopener,noreferrer')}
                >
                    Open capture sheet
                </Button>}
            />
        </div>
    )
}

export const ActionFormModal = ({
    stage,
    item,
    users,
    open,
    operationProgress,
    issueReason,
    handleModal,
    updateStage,
    setIssueReason,
}: IFormModalProps) => {
    const [user, setUser] = useState<number>(0);
    const [dueDate, setDueDate] = useState<string>('');
    const [selectedAction, setSelectedAction] = useState<string>('');

    const handleButtonClick = (clickAction: string, nextStage = "initial") => {
        setSelectedAction(clickAction);
        updateStage(nextStage);
        if (nextStage === "process") {
            handleModal(false, { status: clickAction, id: item.id, prev_status: item.status });
        }
    }

    const onOkClick = () => {
        if (stage === "assign") {
            updateStage("process");
            const selectedUser = users?.filter(ele => ele.id === user)[0];
            if (!selectedUser || !selectedUser?.id || !dueDate) {
                handleModal(true);
            }
            else {
                handleModal(false, {
                    status: selectedAction,
                    id: item.id,
                    prev_status: item.status,
                    ...((selectedUser?.id || dueDate) && {
                        assigned_to_1: selectedUser?.id,
                        assigned_due: dueDate,
                    })
                })
            }
        }
        else if (stage === "report_issue") {
            updateStage("process");
            handleModal(false, {
                status: selectedAction,
                id: item.id,
                prev_status: item.status,
                issue_reason: issueReason
            })
        }
        else {
            handleModal(true);
        }
    }

    const onCancelClick = () => {
        handleModal(true);
    }

    var footer: JSX.Element[] = [];
    if (["initial", "assign", "report_issue"].includes(stage)) {
        footer.push(<Button key="back" onClick={onCancelClick} style={{ borderRadius: '15px' }}>
            Cancel
        </Button>)
    }
    if (stage !== "process") {
        footer.push(<Button key="submit" type="primary" disabled={stage == "initial"} onClick={onOkClick} style={{ borderRadius: '15px' }}>
            Confirm
        </Button>)
    }

    const title: string = {
        "initial": "Perform action",
        "assign": "Perform action",
        "report_issue": "Report issue",
        "process": "Processing...",
        "open_sheet": "Setup sheet",
    }[stage] ?? "";

    return (
        <StyledModal
            open={open}
            onOk={onOkClick}
            onCancel={onCancelClick}
            title={title}
            centered
            footer={footer}
        >
            {stage === "initial" ? <InitialStage item={item} handleButtonClick={handleButtonClick} />
                : stage === "assign" ?
                    <AssignStage
                        users={users}
                        setDueDate={setDueDate}
                        setUser={setUser}
                    />
                    : stage === "process" ?
                        <ProgressStage operationProgress={operationProgress} />
                        : stage === "open_sheet" ?
                            <PostStage operationProgress={operationProgress} />
                            : <IssueStage issueReason={issueReason} setIssueReason={setIssueReason} />
            }
        </StyledModal>
    );
}